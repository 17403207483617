import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

function CustomToolbar(props) {
  const [viewState, setViewState] = useState('week');

  const getCustomToolbar = () => {
    const calendarState = useSelector((state) => state.calendar);

    const goToBack = () => {
      let view = viewState;
      let mDate = props.date;
      let newDate;
      if (view === 'month') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      } else if (view === 'week') {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 1,
          1
        );
      }
      props.onNavigate('prev', newDate);
    };
    const goToNext = () => {
      let view = viewState;
      let mDate = props.date;
      let newDate;
      if (view === 'month') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      } else if (view === 'week') {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 1,
          1
        );
      }
      props.onNavigate('next', newDate);
    };

    useEffect(() => {
      if (calendarState.prevCount) {
        goToBack();
      }
    }, [calendarState.prevCount]);

    useEffect(() => {
      if (calendarState.nextCount) {
        goToNext();
      }
    }, [calendarState.nextCount]);

    const goToDayView = () => {
      props.onView('day');
      setViewState('day');
    };
    const goToWeekView = () => {
      props.onView('week');
      setViewState('week');
    };
    const goToMonthView = () => {
      props.onView('month');
      setViewState('month');
    };
    const goToAgendaView = () => {
      props.onView('agenda');
      setViewState('agenda');
    };

    const goToToday = () => {
      const now = new Date();
      props.date.setMonth(now.getMonth());
      props.date.setYear(now.getFullYear());
      props.date.setDate(now.getDate());
      props.onNavigate('current');
    };

    const goToBackYear = () => {
      let mDate = props.date;
      let newDate = new Date(mDate.getFullYear() - 1, 1);
      props.onNavigate('prev', newDate);
    };

    const goToNextYear = () => {
      let mDate = props.date;
      let newDate = new Date(mDate.getFullYear() + 1, 1);
      props.onNavigate('next', newDate);
    };

    const month = () => {
      const date = moment(props.date);
      let month = date.format('MMMM');
      let day = date.format('D');

      return (
        <span className="rbc-toolbar-label rbc-date">
          <i className="far fa-calendar"></i> <span>{`${month} ${day}`}</span>
        </span>
      );
    };
    const year = () => {
      const date = moment(props.date);
      let year = date.format('YYYY');

      return (
        <span className="rbc-btn-group">
          {viewState === 'month' && (
            <button type="button" onClick={goToBackYear}>
              <span className="prev-icon">&#8249;&#8249;</span>
            </button>
          )}
          <span className="rbc-toolbar-label">{year}</span>
          {viewState === 'month' && (
            <button type="button" onClick={goToNextYear}>
              <span className="prev-icon">&#8250;&#8250;</span>
            </button>
          )}
        </span>
      );
    };

    const day = () => {
      let view = viewState;
      const date = moment(props.date);
      let day;
      if (view === 'day') {
        day = date.format('ddd') + ' ' + date.format('Do');
      }
      return <span className="rbc-toolbar-label">{day}</span>;
    };

    return (
      <div className="rbc-toolbar">
        <div className="rbc-toolbar-item-2">
          {month()}
          <div className="rbc-btn-general">
            <div className="rbc-btn-group">
              <div className="right-block">
                <button className="next-icon today" onClick={goToToday}>
                  Today
                </button>
                <button className="" onClick={goToDayView}>
                  <span className="label-filter-off">Day</span>
                </button>
                <button className="" onClick={goToWeekView}>
                  <span className="label-filter-off">Week</span>
                </button>
                <button className="" onClick={goToMonthView}>
                  <span className="label-filter-off">Month</span>
                </button>
                <button className="" onClick={goToAgendaView}>
                  <span className="label-filter-off">Event list</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <>{getCustomToolbar()}</>;
}
export default CustomToolbar;
