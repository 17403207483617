import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import moment from 'moment';
import ReactTable from 'react-table';
import CsvDownload from 'react-json-to-csv';
import Select from 'react-select';

import axios from '../../../axios';
import default_animal from '../../../assets/images/default-animal.jpg';
import Filters from '../../../components/filters/index.jsx';
import { TagService } from '../../../services';
import { TAG_CSV_TEMPLATE } from '../../../constants/templates';
import SelectionPopover from '../../../components/Popovers/SelectionPopover';
import AddTagModal from '../../../components/Modals/AddTagModal';
import { errorToastHandler } from '../../action_notifier';
import messages from '../../../constants/messages';
import { DATE_FORMAT } from '../../../constants/common';

function Animal(props) {
  let animalId = props.animal[0];
  let animal = props.animals.filter((a) => a.id == animalId)[0];
  let b = moment(animal.age);
  let a = moment();
  let years = a.diff(b, 'year');
  b.add(years, 'years');

  let months = a.diff(b, 'months');
  b.add(months, 'months');

  let days = a.diff(b, 'days');
  return (
    <div className="animal-box">
      <Row>
        <Col sm="12" md="12" lg="3">
          <img className="animal-profile-pic" src={default_animal} alt="" />
        </Col>
        <Col sm="12" md="12" lg="9">
          <Table responsive className="no-border">
            <tbody>
              <tr>
                <td>Official Tag ID</td>
                <td>
                  <span className="text-mute">{animal.eartag_official_id}</span>
                </td>
              </tr>
              <tr>
                <td>Diagri Tag ID</td>
                <td>
                  <span className="text-mute">{animal.id}</span>
                </td>
              </tr>
              <tr>
                <td>Management Tag ID</td>
                <td>
                  <span className="text-mute">{animal.eartag_official_id}</span>
                </td>
              </tr>
              <tr>
                <td>Livestock ID</td>
                <td>
                  <span className="text-mute">{animal.id}</span>
                </td>
              </tr>
              <tr>
                <td>Age</td>
                <td>
                  <span className="text-mute">
                    {years + ' years ' + months + ' months ' + days + ' days'}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Breed</td>
                <td>
                  <span className="text-mute">{animal.breed.display_name}</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

class Tags extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      disableOptions: false,
      activeTab: '1',
      delete_modal: false,
      add_modal: false,
      link_modal: false,
      csv_modal: false,
      tags: [],
      editableIds: [],
      selected_tags_linked: [],
      selected_tags_unlinked: [],
      selected_tags: [],
      selected_animals: [],
      new_tag: {},
      isOpenSelectionPopover: false,
      tableData: [],
      query: '',
      animals: [],
      dropdownOpen: false,
      selected_farm: {},
      farm_opts: [],
      csvfile: '',
      uploading: false,
      selectAll: false,
      search_livestock: '',
      filters: {
        farms: [],
        geofences: [],
        labels: []
      },
      filters_open: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onAnimalSelect = this.onAnimalSelect.bind(this);
    this.getTags = this.getTags.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.linkTag = this.linkTag.bind(this);
    this.getAnimals = this.getAnimals.bind(this);
    this.setDeleteTag = this.setDeleteTag.bind(this);
    this.setDeleteTags = this.setDeleteTags.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.handleFarmChange = this.handleFarmChange.bind(this);
    this.getFarms = this.getFarms.bind(this);
    this.handleCSVChange = this.handleCSVChange.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.filtersClose = this.filtersClose.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
    this.onClickCancelEdit = this.onClickCancelEdit.bind(this);
    this.onClickSaveEdit = this.onClickSaveEdit.bind(this);
    this.tagsTable = React.createRef();
  }
  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.tableData.map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_tags: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.tags.map((val) => {
        checkedCopy.push(val.id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_tags: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }
  onToggleSelectionPopover(isOpen) {
    const open =
      isOpen !== undefined
        ? isOpen
        : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({
      isOpenSelectionPopover: open
    });
  }
  handleCSVChange = (event) => {
    this.setState({
      uploading: true
    });
    let reader = new FileReader();
    reader.readAsText(event.target.files[0]);

    reader.onload = function () {
      this.setState({
        csvfile: reader.result,
        uploading: false
      });
    }.bind(this);
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  };
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };

  handleKeyPress = (charCode) => {
    if (charCode === 13) {
      this.searchTags();
    }
  };

  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  onCheckboxChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      ['selected_tags']: !state[field]
    }));
  };
  onTagSelect = (tag) => {
    let selected_tags = [];
    selected_tags = this.state.selected_tags;

    if (selected_tags.includes(tag)) {
      var index = selected_tags.indexOf(tag);
      if (index > -1) {
        selected_tags.splice(index, 1);
      }
    } else {
      selected_tags.push(tag);
    }

    if (this.state.tags.length == this.state.selected_tags.length) {
      this.setState({
        selectAll: true
      });
    } else {
      this.setState({
        selectAll: false
      });
    }
    this.setState({
      disableOptions: selected_tags.some(
        (item) => this.state.tags.find((t) => t.id === item).deleted_at
      ),
      selected_tags: selected_tags
    });
  };
  onAnimalSelect(animal) {
    let animals = this.state.selected_animals;
    if (animals.includes(animal)) {
      var index = animals.indexOf(animal);
      if (index > -1) {
        animals.splice(index, 1);
      }
    } else {
      animals.push(animal);
    }
    this.setState({
      selected_animals: animals
    });
  }
  componentDidMount() {
    this.getTags();
    this.getAnimals();
    this.getFarms();
  }
  async getTags() {
    let response = await TagService.getAllTags();
    if (response.status == 200) {
      this.setState({
        tags: response.data,
        tableData: response.data.slice(0, 25)
      });
    }
  }

  async searchTags() {
    const payload = { query: this.state.query };
    let response = await TagService.searchTags(payload);
    if (response.status == 200) {
      this.setState({
        tags: response.data
      });
    }
  }

  async linkTag() {
    let animalId = this.state.selected_animals[0];
    let animal = this.state.animals.filter((a) => a.id == animalId)[0];
    let response;
    if (this.state.re_linking) {
      response = await axios.put('links', {
        tag_id: this.state.selected_tags_unlinked[0],
        animal_id: animal.id
      });
    } else {
      response = await axios.post('links', {
        tag_id: this.state.selected_tags_unlinked[0],
        animal_id: animal.id
      });
    }
    if (response.status == 200) {
      this.getTags();
      this.toggleModal('link_modal');
      this.setState({
        re_linking: false,
        linking: false
      });
      window.location.reload();
    }
  }
  async addTag(data) {
    await TagService.addTag(data);
    this.toggleModal('add_modal');
    this.getTags();
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  async getAnimals() {
    let response = await axios.get(
      'animals?query=' + this.state.search_livestock
    );
    if (response.status == 200) {
      this.setState({
        animals: response.data
      });
    }
  }
  setDeleteTag(val) {
    this.setState({
      ...this.state,
      delete_tags: [val]
    });
    this.toggleModal('delete_modal');
  }
  setDeleteTags() {
    let selected;
    selected = this.state.selected_tags;

    this.setState({
      ...this.state,
      delete_tags: selected
    });
    this.toggleModal('delete_modal');
  }
  async onClickDownload() {
    await axios.get('tags/template_export');
  }
  async deleteTag() {
    let response = await axios.delete('tags/archive', {
      data: {
        ids: this.state.delete_tags
      }
    });
    if (response.status == 200) {
      this.getTags();
      this.toggleModal('delete_modal');
    }
  }
  getLabel(id) {
    let tags = this.state.tags.filter((t) => t.id == id);
    if (tags.length > 0) {
      return tags[0].diagri_id;
    } else {
      return '';
    }
  }
  handleCSVFarmChange = (selectedOption) => {
    this.setState({ csv_farm: selectedOption });
  };
  handleFarmChange = (selectedOption) => {
    this.setState({
      selected_farm: selectedOption,
      new_tag: { ...this.state.new_tag }
    });
  };
  async uploadCSV() {
    let csv = this.state.csvfile;
    let response = await axios.post('tags/create/csv', {
      csv: csv
    });
    if (response.status == 200) {
      this.getTags();
      this.toggleModal('csv_modal');
    }
  }
  async getFarms() {
    let response = await axios.get('farms/?all=true', {
      query: this.state.query || null
    });
    if (response.status == 200) {
      let mapped_farms = [];
      for (let farm of response.data) {
        mapped_farms.push({
          value: farm.id,
          label: farm.name
        });
      }
      this.setState({
        farms: response.data,
        farm_opts: mapped_farms
      });
    }
  }
  filtersUpdated(filters) {
    this.setState(
      {
        filters: filters
      },
      () => this.getTags()
    );
  }
  filtersClose() {
    this.setState({ filters_open: false });
  }
  editTags() {
    this.setState({
      editableIds: [...this.state.selected_tags]
    });
  }
  onClickCancelEdit() {
    this.setState({
      editableIds: []
    });
  }
  async onClickSaveEdit() {
    const ids = this.state.editableIds;
    const updatedData = this.state.tableData.filter((item) =>
      ids.includes(item.id)
    );
    const tags = updatedData.map((i) => {
      return { farm_id: i.farm?.id, id: i.id };
    });
    const payload = {
      tags: tags
    };
    try {
      await TagService.updateMany(payload);
      this.setState({
        editableIds: [],
        selected_tags: []
      });
      this.getTags();
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    const columns = [
      {
        Header: () => (
          <div className="position-relative form-check">
            <SelectionPopover
              isOpen={this.state.isOpenSelectionPopover}
              onClickOnThisPage={this.onClickOnThisPage}
              onClickAllRecords={this.onClickAllRecords}
              setOpen={(isOpen) =>
                this.setState({
                  isOpenSelectionPopover:
                    isOpen !== undefined
                      ? isOpen
                      : !this.state.isOpenSelectionPopover
                })
              }
              content={
                <input
                  type="checkbox"
                  defaultChecked={this.state.selectAll}
                  onChange={() => {
                    this.onToggleSelectionPopover();
                    if (this.state.selectAll) {
                      this.setState({
                        selectAll: !this.state.selectAll
                      });
                      this.setState((state) => ({
                        ...state,
                        selected_tags: []
                      }));
                    }
                  }}
                />
              }
            />
          </div>
        ),
        id: 'select',
        headerClassName: 'wordwrap',
        accessor: (d) => {
          return { animal: d.animal, id: d.id };
        },
        Cell: (props) => (
          <FormGroup check>
            <Input
              type="checkbox"
              defaultChecked={this.state.selected_tags.includes(props.value.id)}
              value={props.value.id}
              onChange={(e) =>
                this.onTagSelect(
                  parseInt(e.target.value),
                  props.value.animal ? 'linked' : 'unlinked'
                )
              }
              key={props.value.id}
            />{' '}
          </FormGroup>
        ),
        sortable: false,
        filterable: false,
        maxWidth: 85
      },
      {
        Header: 'Tag ID',
        id: 'diagri_id',
        headerClassName: 'wordwrap',
        accessor: (d) => {
          return { id: d.id, identifier: d.diagri_id };
        },
        Cell: (props) => (
          <span className="number">
            <a
              className="underline"
              onClick={() =>
                this.props.history.push('/administration/tag/' + props.value.id)
              }
              href="javascript:;"
            >
              {props.value.identifier || '/'}
            </a>
          </span>
        ),
        sortMethod: (a, b) => {
          return a?.identifier?.localeCompare(b?.identifier, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        },
        minWidth: 140
      },
      {
        Header: 'Identifier',
        id: 'identifier',
        headerClassName: 'wordwrap',
        accessor: (d) => {
          return { id: d.id, identifier: d.identifier };
        },
        Cell: (props) => (
          <span className="number">
            <a
              className="underline"
              onClick={() =>
                this.props.history.push('/administration/tag/' + props.value.id)
              }
              href="javascript:;"
            >
              {props.value.identifier}
            </a>
          </span>
        ),
        sortMethod: (a, b) => {
          return a?.identifier?.localeCompare(b?.identifier, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        },
        minWidth: 120
      },
      {
        Header: 'Farm ID',
        id: 'farm_id',
        headerClassName: 'wordwrap',
        accessor: (d) => {
          return {
            id: d.id,
            identifier: d.farm?.identifier?.toString() ?? undefined
          };
        },
        Cell: (props) => (
          <span className="number">
            <a
              className="underline"
              onClick={() =>
                this.props.history.push('/administration/tag/' + props.value.id)
              }
              href="javascript:;"
            >
              {props.value.identifier}
            </a>
          </span>
        ),
        sortMethod: (a, b) => {
          return a?.identifier?.localeCompare(b?.identifier, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        }
      },
      {
        Header: 'Farm Name',
        accessor: 'farm',
        headerClassName: 'wordwrap',
        minWidth: 200,
        Cell: (cellProps) => {
          if (this.state.editableIds.includes(cellProps.original.id)) {
            const farmOptions = this.state.farm_opts;
            const currentFarmOption = farmOptions?.find(
              (f) => f.value === cellProps?.value?.id
            );
            return (
              <Select
                value={currentFarmOption}
                onChange={(e) => {
                  let farm = { id: e.value, name: e.label };
                  let data = [...this.state.tableData];
                  data[cellProps.index].farm = { ...farm };
                  this.setState({ tableData: data });
                }}
                options={farmOptions}
              />
            );
          }
          return cellProps?.value?.name ?? '';
        }
      },
      {
        Header: 'Gateway ID',
        id: 'gateway_id',
        headerClassName: 'wordwrap',
        accessor: (d) => {
          return {
            id: d.id,
            identifier: d.gateway?.identifier?.toString() ?? undefined
          };
        },
        Cell: (props) => (
          <span className="number">
            <a
              className="underline"
              onClick={() =>
                this.props.history.push('/administration/tag/' + props.value.id)
              }
              href="javascript:;"
            >
              {props.value.identifier}
            </a>
          </span>
        ),
        sortMethod: (a, b) => {
          return a?.identifier?.localeCompare(b?.identifier);
        }
      },
      {
        Header: 'Gateway Name',
        accessor: 'gateway_name',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Type',
        accessor: 'type',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Deveui',
        accessor: 'deveui',
        headerClassName: 'wordwrap',
        minWidth: 190
      },

      {
        Header: 'Version',
        accessor: 'version',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Firmware Version',
        accessor: 'firmware_version',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Creation At',
        id: 'created_at',
        accessor: (item) => {
          return moment(item.created_at).format(DATE_FORMAT.DATETIME);
        },
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        },
        maxWidth: 180
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: (item) => {
          return moment(item.updated_at).format(DATE_FORMAT.DATETIME);
        },
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        },
        maxWidth: 180
      },
      {
        Header: 'Deleted At',
        id: 'deleted_at',
        accessor: (item) => {
          return item.deleted_at
            ? moment(item.deleted_at).format(DATE_FORMAT.DATETIME)
            : null;
        },
        headerClassName: 'wordwrap',
        Cell: (props) => {
          return <span className="number">{props?.value}</span>;
        },
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        },
        maxWidth: 180
      }
    ];

    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;

      if (row[id] && typeof row[id] == 'object') {
        if (typeof row[id]['identifier'] == 'number') {
          return row?.[id]['identifier'] !== undefined
            ? String(row[id]['identifier']).includes(filter.value)
            : true;
        } else {
          return row?.[id] !== undefined
            ? String(row[id]?.['identifier']?.toLowerCase()).includes(
                filter?.value?.toLowerCase()
              )
            : true;
        }
      } else if (typeof row[id] == 'number') {
        return row[id] !== undefined
          ? String(row[id]).includes(filter.value)
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]?.toLowerCase()).includes(filter.value?.toLowerCase())
          : true;
      }
    }
    return (
      <div>
        <Filters
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm', 'label']}
        ></Filters>

        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10 tag-in-row">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="">Kraal Tags</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Kraal Tags</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>

                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg="6"
                  className="d-flex justify-content-end align-items-center h-100 tag-in-col-right"
                >
                  {this.state.editableIds.length ? (
                    <div>
                      <Button
                        color="primary"
                        className="float-right"
                        onClick={() => this.onClickSaveEdit()}
                      >
                        Save
                      </Button>
                      <Button
                        className="outline float-right"
                        onClick={() => this.onClickCancelEdit()}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <>
                      <InputGroup className="head-search mr-2" size="sm">
                        <Input
                          type="search"
                          id="search"
                          name="search"
                          defaultValue={this.state.query}
                          onChange={(e) =>
                            this.onChange(e.target.value, 'query')
                          }
                          placeholder="Search Kraal Tags"
                          onKeyPress={(e) => this.handleKeyPress(e.charCode)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            onClick={() => this.handleKeyPress(13)}
                            className="rg-pointer"
                          >
                            <i className="fas fa-search"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <ButtonDropdown
                        className="float-right"
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleButton}
                      >
                        <DropdownToggle caret>Actions</DropdownToggle>
                        <DropdownMenu>
                          {!this.state.selected_tags.length > 0 && (
                            <DropdownItem
                              onClick={() => this.toggleModal('add_modal')}
                            >
                              Add Kraal Tag
                            </DropdownItem>
                          )}
                          {this.state.selected_tags.length > 0 && (
                            <DropdownItem onClick={() => this.editTags()}>
                              Edit Kraal Tags
                            </DropdownItem>
                          )}
                          {this.state.selected_tags.length > 0 && (
                            <DropdownItem
                              onClick={() => this.setDeleteTags()}
                              disabled={this.state.disableOptions}
                            >
                              Archive Kraal Tag
                            </DropdownItem>
                          )}
                          <DropdownItem
                            onClick={() => {
                              this.toggleModal('csv_modal');
                            }}
                          >
                            Upload Kraal Tag list
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                      <Button
                        color="primary"
                        className="float-right mr-2"
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            filters_open: !this.state.filters_open
                          })
                        }
                      >
                        <i className="fa fa-filter"></i>Filters
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
              <Col xs="12" md="12" lg="12" className="allow-overflow">
                <ReactTable
                  ref={this.tagsTable}
                  showPagination={this.state.tags.length > 0}
                  minRows={0}
                  data={this.state.tags}
                  columns={columns}
                  resizable={true}
                  defaultPageSize={25}
                  filterable={true}
                  defaultFilterMethod={filterCaseInsensitive}
                  onFetchData={(props) => {
                    const data = props.data.length
                      ? props.sortedData.slice(0, props.pageSize)
                      : this.state.tags;
                    this.setState({ tableData: data });
                  }}
                />
              </Col>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive tags</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive selected tags? This action cannot
            be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteTag()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>

        <AddTagModal
          isOpen={this.state.add_modal}
          className={this.props.className}
          farm_opts={this.state.farm_opts}
          onCancel={() => {
            this.toggleModal('add_modal');
          }}
          onSubmit={(data) => {
            this.addTag(data);
          }}
        />

        <Modal
          size="lg"
          isOpen={this.state.link_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Linking confirmation</b>
            </h5>
            <br />
            <br />
            <br />
            <p className="text-center">
              Tag <b>{this.getLabel(this.state.selected_tags_unlinked[0])}</b>{' '}
              will be linked to
            </p>
            <Animal
              animals={this.state.animals}
              animal={this.state.selected_animals}
            ></Animal>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('link_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.linkTag()}>
              Link
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.csv_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>CSV file upload</b>
            </h5>
            <br />
            <br />
            <br />
            <br />
            <div className="download-block">
              Download
              <CsvDownload
                data={TAG_CSV_TEMPLATE}
                filename={'tags-template.csv'}
              >
                example
              </CsvDownload>
              CSV file.
            </div>
            <br />
            <br />
            <FormGroup>
              <Label>CSV file</Label>
              <Input type="file" onChange={this.handleCSVChange}></Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('csv_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.uploadCSV()}>
              Upload
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Tags;
