import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import moment from 'moment';
import classnames from 'classnames';

import axios from '../../axios';
import RuleSnoozeIcon from '../../components/RuleSnoozeIcon';
import Alerts from '../../components/Alerts';
import PrevNextButtons from '../../components/PrevNextButtons';
import { Link } from 'react-router-dom';
import { DATE_FORMAT } from '../../constants/common';

function Priority(props) {
  let priority = props.priority;
  let colors = {
    priority: 'red',
    high: 'red',
    normal: '#FFBF00',
    medium: '#FFBF00',
    low: '#CCCCCC',
    record: '#CCCCCC'
  };
  if (priority) {
    priority = priority.toLowerCase();
    return (
      <div className="priority-tag" style={{ background: colors[priority] }}>
        {props.priority.charAt(0).toUpperCase() + props.priority.slice(1)}
      </div>
    );
  } else {
    return null;
  }
}

class Rule extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      alert_modal: false,
      delete_modal: false,
      modal_type: '',
      modal_title: '',
      modal_message: '',
      rule: {},
      dropdownOpen: false,
      activeTab: '1'
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.getRule = this.getRule.bind(this);
    this.generateDefinition = this.generateDefinition.bind(this);
    this.toggle = this.toggle.bind(this);
    this.editRule = this.editRule.bind(this);
    this.archiveRule = this.archiveRule.bind(this);
  }
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  async componentDidMount() {
    await this.getRule();
  }
  async getRule() {
    return new Promise(async (resolve) => {
      let response = await axios.get('rules/' + this.props.match.params.id, {
        query: this.state.query
      });
      if (response.status === 200) {
        response.data.isDefault =
          response.data.default_rule === 1 ? true : false;
        this.setState(
          {
            rule: response.data
          },
          () => this.generateDefinition()
        );
        return resolve();
      }
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  editRule() {
    if (this.state.rule.isDefault) {
      let modal = 'alert_modal';
      let type = modal;
      let title = 'Alert';
      let message =
        'This is a default system Rule which can not be edited or archived.';
      this.setState((state) => ({
        ...state,
        [modal]: !state[modal],
        modal_type: type,
        modal_title: title,
        modal_message: message
      }));
    } else {
      this.props.history.push('/rules/builder/' + this.props.match.params.id);
    }
  }

  toggleModal(modal, type) {
    var title = '';
    var message = '';
    if (type === 'Archive') {
      title = 'Archive rule';
      message =
        'Are you sure you want to archive this rule? This action cannot be undone.';
    } else if (type === 'Snooze') {
      title = 'Snooze rule';
      message = 'Are you sure you want to snooze this rule?';
    } else if (type === 'Alert') {
      title = 'Alert';
      message =
        'This is a default system Rule which can not be edited or archived.';
    } else {
      title = 'Unsnooze rule';
      message = 'Are you sure you want to unsnooze this rule?';
    }
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  }
  generateDefinition() {
    let def = [];
    let data = this.state.rule.data;
    let type = this.state.rule.type.value || this.state.rule.type;
    const default_rule = this.state.rule.default_rule;
    let not_defined = <span>{'? '}</span>;
    if (type === 'intermediate_health') {
      def.push(<span>Any animal moves less than </span>);
      if (data.variables) {
        if (data.variables.percentage) {
          def.push(
            <span>
              <span className="bolded">{data.variables.percentage}%</span>
              {' of '}
            </span>
          );
        }

        if (data.variables.calculated_value) {
          def.push(
            <span>
              <span className="bolded">
                {data.variables.calculated_value.value}
              </span>{' '}
            </span>
          );
        }
      }
      if (data.action) {
        def.push(
          <span>
            {'. If rule breached '}
            {data.action.label}{' '}
          </span>
        );
      } else {
        def.push(not_defined);
      }
      this.setState({
        rule: {
          ...this.state.rule,
          definition: def
        }
      });
      return;
    }
    if (
      (type === 'gateway_disconnected' || type === 'tag_disconnected') &&
      default_rule === 1
    ) {
      const selector = data.selector ? data.selector.label : '';
      const condition = data.condition ? data.condition.label : '';
      const action = data.action
        ? '. If rule breached ' + data.action.label
        : '';
      this.setState({
        rule: {
          ...this.state.rule,
          definition: selector + ' ' + condition + action
        }
      });
      return;
    }
    if (data.selector) {
      def.push(
        <span>
          <span className="bolded">{data.selector.label}</span>
          {' livestock labeled '}
        </span>
      );
    } else {
      def.push(not_defined);
    }
    if (data.anchor) {
      def.push(
        <span>
          <span className="bolded">{data.anchor.label}</span>{' '}
        </span>
      );
    } else {
      def.push(not_defined);
    }
    if (data.condition) {
      def.push(<span>{data.condition.label} </span>);
    } else {
      def.push(not_defined);
    }
    if (
      type === 'site_proximity' ||
      type === 'livestock_proximity' ||
      type === 'basic_health'
    ) {
      if (data.variables) {
        if (data.variables.distance) {
          def.push(
            <span>
              <span className="bolded">{data.variables.distance} </span>
            </span>
          );
        } else {
          def.push(not_defined);
        }
        if (data.variables.distance_unit) {
          def.push(
            <span>
              <span className="bolded">
                {data.variables.distance_unit.label}{' '}
                {type != 'basic_health' && ' of '}
              </span>
            </span>
          );
        } else {
          def.push(not_defined);
        }
        if (type === 'basic_health') {
          if (data.variables.timeframe) {
            def.push(
              <span>
                <span className="bolded">{data.variables.timeframe.label}</span>
              </span>
            );
          } else {
            def.push(not_defined);
          }
        }
      }
    }
    if (data.target) {
      def.push(
        <span>
          <span className="bolded">{data.target.label} </span>
        </span>
      );
    }

    if (type === 'site_frequency') {
      if (data.variables) {
        if (data.variables.frequency) {
          def.push(
            <span>
              <span className="bolded">{data.variables.frequency} </span>{' '}
              {' times '}{' '}
            </span>
          );
        } else {
          def.push(not_defined);
        }
        if (data.variables.timeframe) {
          def.push(
            <span>
              <span className="bolded">{data.variables.timeframe.label}</span>
            </span>
          );
        } else {
          def.push(not_defined);
        }
      }
    }
    if (data.action) {
      def.push(
        <span>
          {'. If rule breached '}
          {data.action.label}{' '}
        </span>
      );
    } else {
      def.push(not_defined);
    }
    this.setState({
      rule: {
        ...this.state.rule,
        definition: def
      }
    });
  }
  archiveRule() {
    if (this.state.rule.isDefault) {
      this.toggleModal('alert_modal', 'Alert');
    } else {
      this.toggleModal('delete_modal', 'Archive');
    }
  }
  async deleteRule() {
    var response = {};
    if (this.state.modal_type === 'Archive') {
      response = await axios.delete('rules/' + this.props.match.params.id, {
        data: { force: true }
      });
    } else if (this.state.modal_type === 'Snooze') {
      response = await axios.put(`rules/${this.props.match.params.id}/snooze`, {
        seconds: 86400
      });
    } else {
      response = await axios.put(`rules/${this.props.match.params.id}/snooze`, {
        seconds: -86400
      });
    }

    if (response.status && response.status === 200) {
      this.props.history.push('/rules');
    }
  }

  render() {
    if (this.state.rule.id === null) {
      return null;
    }

    let rulePriority = {
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      priority: 'High',
      normal: 'Medium',
      record: 'Low'
    };

    return (
      <div>
        <Row className="ruls-row">
          <Col xs="8" md="8" lg="8" className="rcol8">
            <h4 className="">Rule {this.state.rule.name}</h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={() => this.props.history.push('/rules')}>
                  List of Rules
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>Rule {this.state.rule.name}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="4" md="4" lg="4" className="rcol4">
            <PrevNextButtons
              history={this.props.history}
              nextId={this.state.rule.next_id}
              prevId={this.state.rule.prev_id}
              path="rule"
            />
            <ButtonDropdown
              className="float-right"
              isOpen={this.state.dropdownOpen}
              toggle={this.toggleButton}
            >
              <DropdownToggle className="remove-mr" caret>
                Actions
              </DropdownToggle>
              <DropdownMenu>
                {!this.state.rule.is_snoozed && (
                  <DropdownItem
                    onClick={() => this.toggleModal('delete_modal', 'Snooze')}
                  >
                    Snooze rule
                  </DropdownItem>
                )}
                {this.state.rule.is_snoozed && (
                  <DropdownItem
                    onClick={() => this.toggleModal('delete_modal', 'Unsnooze')}
                  >
                    Unsnooze rule
                  </DropdownItem>
                )}
                <DropdownItem onClick={() => this.editRule()}>
                  Edit rule
                </DropdownItem>
                <DropdownItem onClick={() => this.archiveRule()}>
                  Archive rule
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row className="pad-15 global-heading-tag">
                <Col xs="12" md="12" lg="6">
                  <h4>
                    Rule {this.state.rule.name || this.state.rule.identifier}{' '}
                    details
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Rule ID</b>
                        </td>
                        <td>
                          {this.state.rule.identifier}{' '}
                          {this.state.rule.is_snoozed && (
                            <RuleSnoozeIcon></RuleSnoozeIcon>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Name</b>
                        </td>
                        <td>{this.state.rule.name}</td>
                      </tr>
                      {this.state.rule.is_snoozed && (
                        <tr>
                          <td>
                            <b>Rule Snoozed Until</b>
                          </td>
                          <td>
                            {moment(this.state.rule.unsnooze_at).format(
                              DATE_FORMAT.DATETIME
                            )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <b>Rule Type</b>
                        </td>
                        <td>{this.state.rule.type_display_name}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Priority</b>
                        </td>
                        <td>
                          {this.state.rule.data && this.state.rule.data.action && (
                            <React.Fragment>
                              <Priority
                                priority={
                                  rulePriority[
                                    this.state.rule.data.action.value
                                  ]
                                }
                              ></Priority>
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Status</b>
                        </td>
                        <td>
                          {this.state.rule.is_snoozed ? 'Snoozed' : 'Active'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Created</b>
                        </td>
                        <td>
                          {moment(this.state.rule.created_at).format(
                            DATE_FORMAT.DATETIME
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Definition</b>
                        </td>
                        <td>{this.state.rule.definition}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Action</b>
                        </td>
                        <td className="capitalize">
                          {this.state.rule.data &&
                            this.state.rule.data.action && (
                              <React.Fragment>
                                {this.state.rule.data.action.label}
                              </React.Fragment>
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Objects</b>
                        </td>
                        <td>
                          {this.state.rule.objects_count} (Livestock:{' '}
                          {this.state.rule.animals_count}, Sites:{' '}
                          {this.state.rule.sites_count}, Geofences:{' '}
                          {this.state.rule.geofences_count}, Gateways:{' '}
                          {this.state.rule.gateway_count}, Tags:{' '}
                          {this.state.rule.tag_count})
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Triggers</b>
                        </td>
                        <td>{this.state.rule.triggers_count}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>User Actions</b>
                        </td>
                        <td>
                          <React.Fragment>
                            Snoozed on{' '}
                            {this.state.rule.snooze_history &&
                              this.state.rule.snooze_history
                                .map((x) =>
                                  moment(x.created_at).format(
                                    DATE_FORMAT.DATETIME
                                  )
                                )
                                .join(', ')}
                            <br></br>
                          </React.Fragment>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Description</b>
                        </td>
                        <td>{this.state.rule.description}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Linked groups</strong>
                        </td>
                        <td>
                          {this.state.rule?.labels?.map((item, index) => {
                            let link = '';
                            if (index > 0) {
                              link = (
                                <>
                                  ,{' '}
                                  <Link to={`/group/${item.id}`}>
                                    {item.identifier}
                                  </Link>
                                </>
                              );
                            } else
                              link = (
                                <Link to={`/group/${item.id}`}>
                                  {item.identifier}
                                </Link>
                              );
                            return link;
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Card>
          <Row className="pad-10 pb-0">
            <Col sm="12" className="mb-0">
              <h5>History</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav tabs className="fancy-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '1'
                    })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Notifications
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <TabContent className="no-bg" activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Alerts
                    type="full"
                    query={{ rule_ids: [this.props.match.params.id] }}
                  ></Alerts>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Card>
        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>{this.state.modal_title}</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.modal_message}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteRule()}>
              {this.state.modal_type}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.alert_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>{this.state.modal_title}</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.modal_message}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('alert_modal')}
            >
              Close
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Rule;
