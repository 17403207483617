import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import axios from '../../axios';
import ReactTable from 'react-table';
import moment from 'moment';
import Filters from '../../components/filters/index.jsx';
import { connect } from 'react-redux';
import { setNotificationList } from '../../redux/actions/notification';
import SelectionPopover from '../../components/Popovers/SelectionPopover';
import { errorToastHandler } from '../../components/action_notifier';
import Priority from '../../components/Priority';
import { NotificationService } from '../../services';
import messages from '../../constants/messages';
import { DATE_FORMAT } from '../../constants/common';

class Notifications extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      delete_modal: false,
      add_modal: false,
      query: '',
      selected_notifications: [],
      isOpenSelectionPopover: false,
      tableData: [],
      selected_livestock: [],
      selected_rules: [],
      livestock_opts: [],
      selectAll: false,
      modal_type: '',
      modal_title: '',
      modal_message: '',
      rules_opts: [],
      notifications: [],
      notificationStatus: {
        unsnoozable: 'Cleared',
        snoozed: 'Snoozed',
        snoozable: 'New'
      },
      dropdownOpen: false,
      filters: {
        farms: props?.location?.filter?.farms || [],
        geofences: [],
        labels: [],
        sites: [],
        animals: [],
        rules: [],
        tags: []
      }
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.getLivestock = this.getLivestock.bind(this);
    this.getRules = this.getRules.bind(this);
    this.onNotSelect = this.onNotSelect.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.filtersClose = this.filtersClose.bind(this);
    this.bulkOperations = this.bulkOperations.bind(this);
    this.clearNotification = this.clearNotification.bind(this);
    this.clearNotifications = this.clearNotifications.bind(this);
    this.snoozeNotification = this.snoozeNotification.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
    this.clearAllNotifications = this.clearAllNotifications.bind(this);
  }
  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.tableData.map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_notifications: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.notifications.map((val) => {
        checkedCopy.push(val.id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_notifications: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
    if (this.state.notificationsCount > 100) {
      errorToastHandler(messages.NOTIFICATIONS_LIMIT);
    }
  }
  onToggleSelectionPopover(isOpen) {
    const open =
      isOpen !== undefined
        ? isOpen
        : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({
      isOpenSelectionPopover: open
    });
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };
  handleKeyPress = (charCode) => {
    if (charCode === 13) {
      this.getNotifications();
    }
  };
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  onCheckboxChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: !state[field]
    }));
  };

  componentDidMount() {
    this.getLivestock();
    this.getRules();
    this.getNotifications();
  }
  async getNotifications() {
    const response = await axios.get('notifications', {
      params: {
        query: this.state.query,
        farm_ids: this.state.filters.farms.map((x) => x.value),
        label_ids: this.state.filters.labels.map((x) => x.value),
        geofence_ids: this.state.filters.geofences.map((x) => x.value),
        site_ids: this.state.filters.sites.map((x) => x.value),
        animal_ids: this.state.filters.animals.map((x) => x.value),
        tag_ids: this.state.filters.tags.map((x) => x.value),
        rule_ids: this.state.filters.rules.map((x) => x.value)
      }
    });
    this.setState({
      notifications: response.data.notifications,
      notificationsCount: response.data.count,
      tableData: response.data.notifications?.slice(0, 25),
      selectAll: false,
      selected_notifications: []
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleModal(modal, type) {
    var title = '';
    var message = '';
    if (type == 'Delete') {
      title = 'Delete Notifications';
      message =
        'Are you sure you want to delete selected Notifications? This action cannot be undone.';
    } else if (type == 'Snooze') {
      title = 'Snooze notifications';
      message =
        'Are you sure you want to snooze selected notifications? This action cannot be undone.';
    } else {
      title = 'Unsnooze notifications';
      message =
        'Are you sure you want to Unsnooze selected notifications? This action cannot be undone.';
    }
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  }
  async getRules() {
    let response = await axios.get('rules');
    this.setState({
      rules_opts: response.data
    });
  }
  async getLivestock() {
    let response = await axios.get('animals');
    this.setState({
      livestock_opts: response.data
    });
  }
  onNotSelect(not) {
    let selected_notifications = this.state.selected_notifications;
    if (selected_notifications.includes(not)) {
      var index = selected_notifications.indexOf(not);
      if (index > -1) {
        selected_notifications.splice(index, 1);
      }
    } else {
      selected_notifications.push(not);
    }

    if (
      this.state.notifications.length ==
      this.state.selected_notifications.length
    ) {
      this.setState({
        selectAll: true
      });
    } else {
      this.setState({
        selectAll: false
      });
    }

    this.setState((state) => ({
      ...state,
      selected_notifications: selected_notifications
    }));
  }
  async addLabel() {}
  onNewLabelChangeLivestock = (selectedOption) => {
    this.setState({
      selected_livestock: selectedOption,
      new_label: {
        ...this.state.new_label,
        livestock: selectedOption.map((x) => x.value)
      }
    });
  };
  onNewLabelChangeRules = (selectedOption) => {
    this.setState({
      selected_rules: selectedOption,
      new_label: {
        ...this.state.new_label,
        rules: selectedOption.map((x) => x.value)
      }
    });
  };
  onNewLabelChange(value, field) {
    this.setState({
      new_label: {
        ...this.state.new_label,
        [field]: value
      }
    });
  }
  async snoozeNotification(id) {
    let response = await axios.put('notifications/' + id + '/snooze');
    if (response.status == 200) {
      this.getNotifications();
    }
  }

  async clearNotification(id) {
    let response = await axios.put('notifications/' + id + '/clear');
    if (response.status == 200) {
      this.getNotifications();
      const updatedNotificationList = this.props.notification.list.filter(
        (notif) => notif.id !== id
      );
      this.props.setNotificationList(updatedNotificationList);
    }
  }
  async clearNotifications() {
    var response = await axios.put('notifications/clear', {
      ids: this.state.selected_notifications
    });
    if (response.status == 200) {
      const updatedNotificationList = this.props.notification.list.filter(
        (notif) => !this.state.selected_notifications.includes(notif.id)
      );
      this.props.setNotificationList(updatedNotificationList);
      this.setState({
        selectAll: false,
        selected_notifications: []
      });
      this.getNotifications();
    }
  }
  async bulkOperations() {
    var response = await axios.put('notifications/snooze', {
      ids: this.state.selected_notifications,
      force: true,
      seconds: 86400
    });
    if (response.status == 200) {
      this.setState({
        delete_modal: false,
        selectAll: false,
        selected_notifications: []
      });
      this.getNotifications();
    }
  }
  async clearAllNotifications() {
    const response = await NotificationService.clearAllNotifications();
    if (response.status == 200) {
      this.props.setNotificationList([]);
      this.setState({
        selectAll: false,
        selected_notifications: []
      });
      this.getNotifications();
    }
  }

  async snoozeAllNotifications() {
    const response = await NotificationService.snoozeAllNotifications();
    if (response.status == 200) {
      this.setState({
        selectAll: false,
        selected_notifications: []
      });
      this.getNotifications();
    }
  }



  filtersUpdated(filters) {
    this.setState(
      {
        filters: filters
      },
      () => this.getNotifications()
    );
  }
  filtersClose() {
    this.setState({ filters_open: false });
  }
  getTrProps = (state, rowInfo) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.row.actions.clear ? '#f0f0f0' : '#ffffff'
        }
      };
    }
    return {};
  };
  render() {
    const columns = [
      {
        Header: () => (
          <div className="position-relative form-check">
            <SelectionPopover
              isOpen={this.state.isOpenSelectionPopover}
              onClickOnThisPage={this.onClickOnThisPage}
              onClickAllRecords={this.onClickAllRecords}
              setOpen={(isOpen) =>
                this.setState({
                  isOpenSelectionPopover:
                    isOpen !== undefined
                      ? isOpen
                      : !this.state.isOpenSelectionPopover
                })
              }
              content={
                <input
                  type="checkbox"
                  defaultChecked={this.state.selectAll}
                  onChange={() => {
                    this.onToggleSelectionPopover();
                    if (this.state.selectAll) {
                      this.setState({
                        selectAll: !this.state.selectAll
                      });
                      this.setState((state) => ({
                        ...state,
                        selected_notifications: []
                      }));
                    }
                  }}
                />
              }
            />
          </div>
        ),
        id: 'select',
        accessor: (d) => {
          return { id: d.id, tag: d.tag };
        },
        Cell: (props) => (
          <FormGroup check>
            <Input
              type="checkbox"
              defaultChecked={this.state.selected_notifications.includes(
                props.value.id
              )}
              value={props.value.id}
              onChange={(e) => this.onNotSelect(parseInt(e.target.value))}
              key={props.value.id}
            />{' '}
          </FormGroup>
        ),
        headerClassName: 'wordwrap',
        sortable: false,
        filterable: false,
        maxWidth: 85
      },
      {
        Header: 'Notification ID',
        id: 'id',
        accessor: (d) => {
          return { id: d.id, identifier: d.identifier };
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier);
        },
        Cell: (props) => (
          <a
            href="#"
            onClick={() =>
              this.props.history.push('notification/' + props.value.id)
            }
          >
            {props.value.identifier}
          </a>
        ),
        minWidth: 130,
        maxWidth: 130
      },
      {
        Header: 'Notification Priority',
        id: 'priority',
        accessor: (d) => {
          return {
            priority: d.priority,
            identifier: d.priority,
            index: d.priority_index
          };
        },
        headerClassName: 'wordwrap',
        Cell: (props) => (
          <Priority
            priority={props.value.priority}
            isClear={props.row.actions.clear}
          ></Priority>
        ),
        sortMethod: (a, b) => {
          return a.index - b.index;
        },
        maxWidth: 130
      },
      {
        Header: 'Notification Type',
        accessor: 'type',
        headerClassName: 'wordwrap capitalize',
        className: 'capitalize',
        maxWidth: 130
      },
      {
        Header: 'Notification Rule',
        accessor: 'rule.name',
        headerClassName: 'wordwrap',
        minWidth: 300
      },
      {
        Header: 'Livestock Count',
        accessor: 'animals',
        headerClassName: 'wordwrap',
        Cell: (props) => {
          return <div>{props.original.animals?.length ?? 0}</div>;
        },
        minWidth: 100
      },
      {
        Header: 'Notification Trigger Date',
        id: 'notiifcationDate',
        accessor: (d) => {
          return moment(d.created_at).format(DATE_FORMAT.DATETIME);
        },
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        },
        maxWidth: 180
      },

      {
        Header: 'Snooze / Clear',
        id: 'actions',
        accessor: (d) => {
          return { snooze: d.status, clear: d.cleared_at != null, id: d.id };
        },
        headerClassName: 'wordwrap',
        sortable: false,
        filterable: false,
        Cell: (props) => (
          <div>
            <i
              onClick={() => this.snoozeNotification(props.value.id)}
              className={'not-action mdi mdi-alarm ' + props.value.snooze}
            ></i>
            <i
              onClick={() => this.clearNotification(props.value.id)}
              className={
                'not-action clear mdi mdi-check-circle-outline ' +
                props.value.clear
              }
            ></i>
          </div>
        ),
        maxWidth: 100
      },
      {
        Header: 'Notification Status',
        id: 'notificationStat',
        headerClassName: 'wordwrap',
        //filterable:false,
        accessor: (d) => {
          return this.state.notificationStatus[d.status];
        },
        // Cell: props=><React.Fragment>{this.state.notificationStatus[props.value.snooze]}</React.Fragment>
        maxWidth: 120
      }
    ];

    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      if (filter.id === 'animals') {
        return row?.[id] !== undefined
          ? Number(row[id]?.length) === Number(filter.value)
          : true;
      }
      if (typeof row[id] == 'object') {
        return row[id] !== undefined
          ? String(row[id]['identifier'].toLowerCase()).includes(
              filter.value.toLowerCase()
            )
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
          : true;
      }
    }

    return (
      <div>
        <Filters
          farms={this.state.filters.farms}
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['geofence', 'site', 'animal', 'tag', 'rule', 'farm']}
        ></Filters>

        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="">Notifications</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Notifications</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>
                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg="6"
                  className="d-flex justify-content-end align-items-center h-100"
                >
                  <InputGroup className="head-search mr-2" size="sm">
                    <Input
                      type="search"
                      id="search-notification"
                      name="search"
                      value={this.state.query}
                      onChange={(e) => this.onChange(e.target.value, 'query')}
                      placeholder="Search Notifications"
                      onKeyPress={(e) => this.handleKeyPress(e.charCode)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        onClick={() => this.handleKeyPress(13)}
                        className="rg-pointer"
                      >
                        <i className="fas fa-search"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {/* this.state.selected_notifications.length > 0*/}
                  {true && (
                    <ButtonDropdown
                      className="float-right"
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggleButton}
                    >
                      <DropdownToggle caret>Actions</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          color="primary"
                          className="float-right"
                          onClick={() => this.clearNotifications()}
                        >
                          Clear notifications
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            this.toggleModal('delete_modal', 'Snooze')
                          }
                        >
                          Snooze notifications
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.clearAllNotifications()}
                        >
                          Clear all new notifications
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.snoozeAllNotifications()}
                        >
                          Snooze all new notifications
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  )}
                  <Button
                    color="primary"
                    className="float-right mr-2"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        filters_open: !this.state.filters_open
                      })
                    }
                  >
                    <i className="fa fa-filter"></i>Filters
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12" className="notification-table">
                  <ReactTable
                    showPagination={this.state?.notifications?.length > 0}
                    minRows={0}
                    columns={columns}
                    data={this.state.notifications}
                    resizable={true}
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                    defaultPageSize={25}
                    onFetchData={(props) => {
                      const data = props.data.length
                        ? props.sortedData.slice(0, props.pageSize)
                        : this.state.notifications;
                      this.setState({ tableData: data });
                    }}
                    getTrProps={this.getTrProps}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>{this.state.modal_title}</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.modal_message}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.bulkOperations()}>
              {this.state.modal_type}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => ({
    setNotificationList: (data) => {
      dispatch(setNotificationList(data));
    }
  })
)(Notifications);
