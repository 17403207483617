import React from 'react';
import {
  Row,
  Col,
  Card,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';

import axios from '../../axios';
import { setNotificationList } from '../../redux/actions/notification';
import PrevNextButtons from '../../components/PrevNextButtons';
import Priority from '../../components/Priority';
import { DATE_FORMAT } from '../../constants/common';

class NotificationPage extends React.Component {
  //Tabs
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      delete_modal: false,
      add_modal: false,
      query: '',
      selected_labels: [],
      selected_livestock: [],
      selected_rules: [],
      livestock_opts: [],
      notificationStatus: {
        unsnoozable: 'Cleared',
        snoozed: 'Snoozed',
        snoozable: 'New'
      },
      rules_opts: [],
      notification: {
        name: '',
        id: null,
        priority: '',
        rule: {},
        date: '',
        type: '',
        description: '',
        tags: [],
        gateways: []
      },
      new_label: {
        name: '',
        color: '',
        rules: [],
        livestock: []
      },
      dropdownOpen: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.getNotification = this.getNotification.bind(this);
    this.getLivestock = this.getLivestock.bind(this);
    this.getRules = this.getRules.bind(this);
  }
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  onCheckboxChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: !state[field]
    }));
  };

  ageformat(created_at) {
    if (created_at) {
      let b = moment(created_at);
      let a = moment();
      let years = a.diff(b, 'year');
      b.add(years, 'years');
      let months = a.diff(b, 'months');
      b.add(months, 'months');
      let days = a.diff(b, 'days');
      b.add(days, 'days');
      let hours = a.diff(b, 'hours');
      b.add(hours, 'hours');
      let mins = a.diff(b, 'minutes');
      return (
        years + 'y ' + months + 'm ' + days + 'd ' + hours + 'h ' + mins + 'm'
      );
    } else {
      return '';
    }
  }
  async componentDidMount() {
    this.getLivestock();
    await this.getNotification();
    let that = this;
    that.setState({
      ...that.state,
      notification: {
        ...that.state.notification,
        age: this.ageformat(that.state.notification.created_at)
      }
    });
    setTimeout(function () {
      that.setState({
        ...that.state,
        notification: {
          ...that.state.notification,
          age: that.ageformat(that.state.notification.created_at)
        }
      });
    }, 60000);
  }
  async getNotification() {
    return new Promise(async (resolve) => {
      let response = await axios.get(
        'notifications/' + this.props.match.params.id,
        {
          query: this.state.query
        }
      );

      if (response.status == 200) {
        this.setState(
          {
            notification: response.data
          },
          resolve()
        );
      }
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  async getRules() {
    let response = await axios.get('rules');
    this.setState({
      rules_opts: response.data
    });
  }
  async getLivestock() {
    let response = await axios.get('animals');
    this.setState({
      livestock_opts: response.data
    });
  }
  onLabelSelect() {}
  async addLabel() {}
  onNewLabelChangeLivestock = (selectedOption) => {
    this.setState({
      selected_livestock: selectedOption,
      new_label: {
        ...this.state.new_label,
        livestock: selectedOption.map((x) => x.value)
      }
    });
  };
  onNewLabelChangeRules = (selectedOption) => {
    this.setState({
      selected_rules: selectedOption,
      new_label: {
        ...this.state.new_label,
        rules: selectedOption.map((x) => x.value)
      }
    });
  };
  onNewLabelChange(value, field) {
    this.setState({
      new_label: {
        ...this.state.new_label,
        [field]: value
      }
    });
  }
  async snoozeNotification() {
    await axios.put('notifications/' + this.props.match.params.id + '/snooze');
    this.getNotification();
  }
  async clearNotification() {
    const id = this.props.match.params.id;
    let response = await axios.put('notifications/' + id + '/clear');
    if (response.status === 200) {
      const updatedNotificationList = this.props.notification.list.filter(
        (notif) => notif.id != id
      );

      this.props.setNotificationList(updatedNotificationList);
    }
    this.getNotification();
  }
  render() {
    return (
      <div>
        <Row className="">
          <Col xs="6" md="6" lg="6">
            <h4 className="">
              Notification {this.state.notification.identifier}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a
                  href="#"
                  onClick={() => this.props.history.push('/notifications')}
                >
                  List of Notifications
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                Notification {this.state.notification.identifier}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <PrevNextButtons
              history={this.props.history}
              nextId={this.state.notification.next_id}
              prevId={this.state.notification.prev_id}
              path="notification"
            />
            <ButtonDropdown
              className="float-right"
              isOpen={this.state.dropdownOpen}
              toggle={this.toggleButton}
            >
              <DropdownToggle className="remove-mr" caret>
                Actions
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  color="primary"
                  className="float-right"
                  onClick={() => this.clearNotification()}
                >
                  Clear Notification
                </DropdownItem>
                {this.state.notificationStatus[
                  this.state.notification.status
                ] != 'Cleared' && (
                  <DropdownItem onClick={() => this.snoozeNotification()}>
                    Snooze notification
                  </DropdownItem>
                )}
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row className="pad-15 global-heading-tag">
                <Col xs="12" md="12" lg="6">
                  <h5 className="details-title">
                    Notification {this.state.notification.identifier} details
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Notification ID</b>
                        </td>
                        <td>{this.state.notification.identifier}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Notification Message</b>
                        </td>
                        <td>{this.state.notification.message}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Notification Priority</b>
                        </td>
                        <td>
                          <Priority
                            priority={this.state.notification.priority}
                          ></Priority>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Notification Status</b>
                        </td>
                        <td>
                          <div
                            className="priority-tag"
                            style={{ background: 'gray' }}
                          >
                            {
                              this.state.notificationStatus[
                                this.state.notification.status
                              ]
                            }
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Notification Type</b>
                        </td>
                        <td className="capitalize">
                          {this.state.notification.type}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Notification Trigger Time</b>
                        </td>
                        <td>
                          {moment(this.state.notification.created_at).format(
                           DATE_FORMAT.DATETIME
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Notification Age</b>
                        </td>
                        <td>{this.state.notification.age}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>User Actions</b>
                        </td>
                        <td>
                          <React.Fragment>
                            Snoozed on : &nbsp;
                            {this.state.notification.snooze_history &&
                              this.state.notification.snooze_history
                                .map((x) =>
                                  moment(x.created_at).format(
                                    DATE_FORMAT.DATETIME
                                  )
                                )
                                .join(', ')}
                            <br></br>
                            Cleared on : &nbsp;
                            {this.state.notification.clear_history &&
                              this.state.notification.clear_history
                                .map((x) =>
                                  moment(x.created_at).format(
                                    DATE_FORMAT.DATETIME
                                  )
                                )
                                .join(', ')}
                          </React.Fragment>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule ID</b>
                        </td>
                        <td>
                          {this.state.notification.rule && (
                            <a
                              href="#"
                              onClick={() =>
                                this.props.history.push(
                                  '/rule/' + this.state.notification.rule.id
                                )
                              }
                            >
                              {this.state.notification.rule.identifier}
                            </a>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Livestock Count</b>
                        </td>
                        <td>
                          <div>
                            {this.state.notification?.livestocks?.length ?? 0}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Kraal Tag ID</b>
                        </td>
                        <td>
                          {this.state.notification.tags &&
                            this.state.notification.tags.length > 0 &&
                            this.state.notification.type !== 'tag' &&
                            this.state.notification.tags
                              .map((x) => x.diagri_id)
                              .join(', ')}
                          {this.state.notification.tags &&
                            this.state.notification.tags.length > 0 &&
                            this.state.notification.type === 'tag' &&
                            this.state.notification.tags
                              .map((j, index) => {
                                return (
                                  <a
                                    key={index}
                                    href="#"
                                    onClick={() =>
                                      this.props.history.push('/tag/' + j.id)
                                    }
                                  >
                                    {j.diagri_id}
                                  </a>
                                );
                              })
                              .reduce((prev, curr) => [prev, ', ', curr])}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Livestock ID&apos;s</b>
                        </td>
                        <td>
                          {this.state.notification.livestocks &&
                            this.state.notification.livestocks.length > 0 &&
                            this.state.notification.livestocks
                              .map((x, index) => {
                                return (
                                  <a
                                    key={index}
                                    href="javascript:;"
                                    onClick={() =>
                                      this.props.history.push(
                                        this.state.notification.type ===
                                          'geolocation'
                                          ? '/tracking/' + x.id
                                          : '/animal/' + x.id
                                      )
                                    }
                                  >
                                    {x.identifier}
                                  </a>
                                );
                              })
                              .reduce((prev, curr) => [prev, ', ', curr])}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Mgmt Tag ID</b>
                        </td>
                        <td>
                          {this.state.notification.livestocks &&
                            this.state.notification.livestocks.length > 0 &&
                            this.state.notification.livestocks
                              .map((x) => x.management_tag_id)
                              .join(', ')}
                        </td>
                      </tr>

                      {this.state.notification.type == 'gateway' && (
                        <tr>
                          <td>
                            <b>Gateway ID</b>
                          </td>
                          <td>
                            {this.state.notification.gateways &&
                              this.state.notification.gateways.length > 0 &&
                              this.state.notification.gateways
                                .map((i, index) => {
                                  return (
                                    <a
                                      key={index}
                                      href="#"
                                      onClick={() =>
                                        this.props.history.push(
                                          '/gateway/' + i.id
                                        )
                                      }
                                    >
                                      {i.identifier}
                                    </a>
                                  );
                                })
                                .reduce((prev, curr) => [prev, ', ', curr])}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => ({
    setNotificationList: (data) => {
      dispatch(setNotificationList(data));
    }
  })
)(NotificationPage);
