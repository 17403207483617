import moment from 'moment';
import { DATE_FORMAT } from '../constants/common';

export const getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

export const formatDate = (date) => {
  if (!date) return null;
  return moment(date).format(DATE_FORMAT.DATETIME);
};
