import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import AnimalPopup from '../../components/animal_popup';
import axios from '../../axios';
import default_animal from '../../assets/images/default-animal.jpg';
import Filters from '../../components/filters/index.jsx';
import SelectionPopover from '../../components/Popovers/SelectionPopover';
import { errorToastHandler } from '../../components/action_notifier';
import { capitalize, downloadCSV } from '../../helpers/common';
import 'react-datepicker/dist/react-datepicker.css';
import StatusIndicator from '../../components/statusIndicator';
import { connect } from 'react-redux';
import { getTableColumns } from '../../helpers/subscription';
import { FEATURE_LIST } from '../../constants/subscription';
import messages from '../../constants/messages';
import CustomTable from '../../components/CustomTable';
import {
  DATE_FORMAT,
  EDIT_COMPONENT_TYPE,
  SELECT_OPTIONS
} from '../../constants/common';
import {
  formatAnimalList,
  formatAnimalsPayload
} from '../../helpers/livestock';
import { AnimalService } from '../../services';
import { ANIMAL_ERRORS } from '../../constants/livestock';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';

function Livestock(props) {
  let animalId = props.animal[0];
  let animal = props.animals.filter((a) => a.id == animalId)[0];
  let b = moment(animal.age);
  let a = moment();
  let years = a.diff(b, 'year');
  b.add(years, 'years');

  let months = a.diff(b, 'months');
  b.add(months, 'months');

  let days = a.diff(b, 'days');

  return (
    <div className="animal-box">
      <Row>
        <Col sm="12" md="12" lg="3">
          <img className="animal-profile-pic" src={default_animal} alt="" />
        </Col>
        <Col sm="12" md="12" lg="9">
          <Table responsive className="no-border">
            <tbody>
              <tr>
                <td>Official Tag ID</td>
                <td>
                  <span className="text-mute">{animal.eartag_official_id}</span>
                </td>
              </tr>
              <tr>
                <td>Kraal Tag ID</td>
                <td>
                  <span className="text-mute">{animal.id}</span>
                </td>
              </tr>
              <tr>
                <td>Management Tag ID</td>
                <td>
                  <span className="text-mute">{animal.eartag_official_id}</span>
                </td>
              </tr>
              <tr>
                <td>Livestock ID</td>
                <td>
                  <span className="text-mute">{animal.id}</span>
                </td>
              </tr>
              <tr>
                <td>Age</td>
                <td>
                  <span className="text-mute">
                    {years + ' years ' + months + ' months ' + days + ' days'}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Breed</td>
                <td>
                  <span className="text-mute">{animal.breed.display_name}</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
class Livestocks extends React.Component {
  //Tabs
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      isGeofenceChanged: false,
      editableIds: [],
      isOpenSelectionPopover: false,
      tableData: [],
      activeTab: '1',
      isOpenConfirmationModal: false,
      delete_modal: false,
      add_modal: false,
      label_modal: false,
      link_modal: false,
      csv_modal: false,
      selectAll: false,
      sucess_modal: false,
      selectAllUnLinked: false,
      tags: [],
      uploadMessage: '',
      selected_animals: [],
      selected_animals_linked: [],
      selected_animals_unlinked: [],
      selected_tags: [],
      new_animal: {},
      selected_geofences: [],
      showAnimalPopup: false,
      selected_specie: null,
      selected_sex: null,
      selected_farm: null,
      query_tag: '',
      query: '',
      animals: [],
      dropdownOpen: false,
      specie_opts: [
        { value: 'bovine', label: 'Bovine' },
        { value: 'ovine', label: 'Ovine' },
        { value: 'caprine', label: 'Caprine' }
      ],
      breed_opts: [],
      farm_opts: [],
      csv_farm: undefined,
      selected_labels: [],
      filters_open: false,
      animalType: 'linked',
      filters: {
        farms: props?.location?.filter?.farms || [],
        geofences: [],
        labels: []
      },
      delete_animals: [],
      type_opts: [],
      selected_type: {},
      showPopup: false,
      tempModalValue: null
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeTag = this.onChangeTag.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onLivestockSelect = this.onLivestockSelect.bind(this);
    this.onTagSelect = this.onTagSelect.bind(this);
    this.getTags = this.getTags.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.getFarms = this.getFarms.bind(this);
    this.linkTag = this.linkTag.bind(this);
    this.getLivestocks = this.getLivestocks.bind(this);
    this.setDeleteLivestock = this.setDeleteLivestock.bind(this);
    this.setDeleteLivestocks = this.setDeleteLivestocks.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.onNewLivestockFileChange = this.onNewLivestockFileChange.bind(this);
    this.handleCSVFarmChange = this.handleCSVFarmChange.bind(this);
    this.handleCSVChange = this.handleCSVChange.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleLabelAddChange = this.handleLabelAddChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleFenceChange = this.handleFenceChange.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.filtersClose = this.filtersClose.bind(this);
    this.onNewLivestockChangeDate = this.onNewLivestockChangeDate.bind(this);
    this.linkLabels = this.linkLabels.bind(this);
    this.addMeasures = this.addMeasures.bind(this);
    this.addBreeding = this.addBreeding.bind(this);
    this.addTreatments = this.addTreatments.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickSaveEdit = this.onClickSaveEdit.bind(this);
    this.onClickCancelEdit = this.onClickCancelEdit.bind(this);
    this.handleUpdateTableData = this.handleUpdateTableData.bind(this);
    this.getTypes = this.getTypes.bind(this);
    this.handleTableValueChange = this.handleTableValueChange.bind(this);
    this.onAnimalSelect = this.onAnimalSelect.bind(this);
    this.closeAnimalPopup = this.closeAnimalPopup.bind(this);
  }

  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.tableData.map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_animals: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.animals.map((val) => {
        checkedCopy.push(val.id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_animals: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }
  onToggleSelectionPopover(isOpen) {
    const open =
      isOpen !== undefined
        ? isOpen
        : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({
      isOpenSelectionPopover: open
    });
  }
  onTagSelect = (tag) => {
    let selected_tags = this.state.selected_tags;

    if (selected_tags.includes(tag)) {
      var index = selected_tags.indexOf(tag);
      if (index > -1) {
        selected_tags.splice(index, 1);
      }
    } else {
      selected_tags.push(tag);
    }
    this.setState((state) => ({
      ...state,
      selected_tags: selected_tags
    }));
  };
  handleTableValueChange(key, value, cellProps) {
    let data = [...this.state.tableData];
    data[cellProps.index][key] = value;
    this.setState({ tableData: data });
  }
  handleCSVChange = (event) => {
    this.setState({
      csvfile: event.target.files[0],
      uploading: false
    });
  };
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };
  handleKeyPress = (charCode) => {
    if (charCode === 13) {
      this.getLivestocks();
    }
  };
  onChangeTag = (value, field) => {
    this.setState(
      (state) => ({
        ...state,
        [field]: value
      }),
      () => this.getTags()
    );
  };
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  getBase64(file) {
    let document = '';
    let reader = new FileReader();
    document = reader.readAsArrayBuffer(file);
    reader.onload = function () {
      document = reader.result;
      this.setState({
        new_animal: {
          ...this.state.new_animal,
          photo: `data:${file.type}; base64, ${this._arrayBufferToBase64(
            reader.result
          )}`
        }
      });
    }.bind(this);
    reader.onerror = function () {};
    return document;
  }
  async uploadCSV() {
    let csv = this.state.csvfile;
    let formData = new FormData();
    formData.append('file', csv);
    formData.append('farm_id', this.state.csv_farm?.value);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };

    const response = await axios.post('animals/csv', formData, config);
    this.getLivestocks();
    this.toggleModal('csv_modal');
    this.setState(
      {
        uploadMessage: response.data.message
      },
      () => this.toggleModal('sucess_modal')
    );
  }
  async getLabels() {
    let response = await axios.get('labels?type=custom');
    if (response.status == 200) {
      this.setState({
        label_opts: response.data.map((x) => {
          return { value: x.id, label: x.name, color: x.colour };
        })
      });
    }
  }
  onNewLivestockFileChange = (e) => {
    let file = e.target.files[0];
    this.setState((state) => ({
      ...state,
      new_animal: {
        ...this.state.new_animal,
        photo: this.getBase64(file)
      }
    }));
  };
  onCheckboxChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: !state[field]
    }));
  };
  onLivestockSelect = (animal) => {
    let field = 'selected_animals';
    let selected_animals = this.state.selected_animals;

    if (selected_animals.includes(animal)) {
      var index = selected_animals.indexOf(animal);
      if (index > -1) {
        selected_animals.splice(index, 1);
      }
    } else {
      selected_animals.push(animal);
    }

    if (this.state.animals.length === this.state.selected_animals.length) {
      this.setState({
        selectAll: true
      });
    } else {
      this.setState({
        selectAll: false
      });
    }

    this.setState((state) => ({
      ...state,
      [field]: selected_animals
    }));
  };

  componentDidMount() {
    this.getTags();
    this.getLivestocks();
    this.getFarms();
    this.getLabels();
    this.getBreeds();
    this.getTypes();
  }

  async getTags() {
    let response = await axios.get('tags', {
      params: {
        only_linked: false,
        query: this.state.query_tag
      }
    });
    if (response.status == 200) {
      this.setState({
        tags: response.data
      });
    }
  }
  async linkTag() {
    let animalId = this.state.selected_animals[0];
    let animal = this.state.animals.filter((a) => a.id == animalId)[0];
    let response;
    if (this.state.re_linking) {
      response = await axios.put('links', {
        tag_id: this.state.selected_tags[0],
        animal_id: animal.id
      });
    } else {
      response = await axios.post('links', {
        tag_id: this.state.selected_tags[0],
        animal_id: animal.id
      });
    }
    if (response.status == 200) {
      this.getTags();
      this.toggleModal('link_modal');
      this.setState({
        re_linking: false,
        linking: false
      });
      window.location.reload();
    }
  }
  handleCSVFarmChange = (selectedOption) => {
    this.setState({ csv_farm: selectedOption });
  };
  async getFarms() {
    let response = await axios.get('farms?with_details=true', {
      query: this.state.query || null
    });
    if (response.status == 200) {
      let mapped_farms = [];
      for (let farm of response.data) {
        mapped_farms.push({
          value: farm.id,
          label: farm.name,
          id: farm.id,
          meta: {
            features: farm.geofences
          }
        });
      }
      this.setState({
        farms: response.data,
        farm_opts: mapped_farms
      });
    }
  }
  toggle(tab, type) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        animalType: type
      });
    }
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  async getLivestocks() {
    let response = await axios.get('animals', {
      params: {
        query: this.state.query,
        farm_ids: this.state.filters.farms.map((x) => x.value),
        geofence_ids: this.state.filters.geofences.map((x) => x.value),
        label_ids: this.state.filters.labels.map((x) => x.value)
      }
    });
    if (response.status == 200) {
      const animalsList = formatAnimalList(response.data);
      let maleLivestocks = animalsList.filter((s) => s.sex === 'male');
      let femaleLivestocks = animalsList.filter((s) => s.sex === 'female');
      this.setState({
        tableData: [...animalsList],
        animals: [...animalsList],
        actualData: animalsList.map((i) => {
          return { ...i };
        }),
        maleLivestocks: maleLivestocks,
        femaleLivestocks: femaleLivestocks
      });
    }
  }
  setDeleteLivestock(val) {
    this.setState({
      ...this.state,
      delete_tags: [val]
    });
    this.toggleModal('delete_modal');
  }
  setDeleteLivestocks() {
    let selected;
    if (this.state.activeTab == '1') {
      selected = this.state.selected_tags_linked;
    } else {
      selected = this.state.selected_tags_unlinked;
    }
    this.setState({
      ...this.state,
      delete_tags: selected
    });
    this.toggleModal('delete_modal');
  }
  async deleteTag() {
    let isAnimalWithTag = false;
    for (let index = 0; index < this.state.selected_animals.length; index++) {
      const animalId = this.state?.selected_animals?.[index];
      const animal = this.state.animals.find((a) => a.id === animalId);
      if (animal?.tag?.id) {
        isAnimalWithTag = true;
        break;
      }
    }
    if (isAnimalWithTag) {
      errorToastHandler(messages.ANIMAL_UNLINK_TAG_REQUIRED);
    } else {
      let response = await axios.delete('animals/bulk', {
        data: {
          ids: this.state.selected_animals
        }
      });
      if (response.status == 200) {
        this.getTags();
        this.getLivestocks();
        this.toggleModal('delete_modal');
      }
    }
  }
  getLabel(id) {
    let tags = this.state.tags.filter((t) => t.id == id);
    if (tags.length > 0) {
      return tags[0].diagri_id;
    } else {
      return '';
    }
  }
  async getBreeds() {
    let response = await axios.get('breeds');
    if (response.status == 200) {
      this.setState({
        breed_opts: response.data.map((x) => {
          return { value: x.code, label: x.display_name };
        })
      });
    }
  }
  async getTypes() {
    let response = await axios.get('/animals/stock-types');
    if (response.status == 200) {
      this.setState({
        type_opts: response.data
      });
    }
  }
  onNewLivestockChangeDate(val) {
    this.setState({
      new_animal: {
        ...this.state.new_animal,
        dob_at: moment(val, 'MMMM d, yyyy h:mm aa').format(),
        dob_at_holder: new Date(val)
      }
    });
  }
  handleSpecieChange = (selectedOption) => {
    this.setState({
      selected_specie: selectedOption,
      new_animal: { ...this.state.new_animal, specie: selectedOption.value }
    });
  };
  handleBreedChange = (selectedOption) => {
    this.setState({
      selected_breed: selectedOption,
      new_animal: { ...this.state.new_animal, breed: selectedOption.label }
    });
  };
  handleSexChange = (selectedOption) => {
    this.setState({
      selected_sex: selectedOption,
      new_animal: { ...this.state.new_animal, sex: selectedOption.value }
    });
  };
  handleFarmChange = (selectedOption) => {
    this.setState({
      selected_farm: selectedOption,
      selected_geofences: [],
      new_animal: { ...this.state.new_animal, farm_id: selectedOption.value }
    });
  };
  handleLabelChange = (selectedOption) => {
    this.setState({
      selected_labels: selectedOption,
      new_animal: {
        ...this.state.new_animal,
        group_ids: selectedOption.map((x) => x.value)
      }
    });
  };
  handleLabelAddChange = (selectedOption) => {
    this.setState({ selected_labels_add: selectedOption });
  };
  handleFenceChange = (selectedOption) => {
    this.setState({
      selected_geofences: selectedOption,
      new_animal: {
        ...this.state.new_animal,
        geofence_ids: selectedOption.value
      },
      animal_edit: {
        ...this.state.animal_edit,
        geofence_ids: selectedOption.value
      }
    });
  };
  handleTypeChange = (selectedOption) => {
    this.setState({
      selected_type: selectedOption,
      new_animal: {
        ...this.state.new_animal,
        stock_type: selectedOption.value
      },
      animal_edit: {
        ...this.state.animal_edit,
        stock_type: selectedOption.value
      }
    });
  };
  filtersUpdated(filters) {
    this.setState(
      {
        filters: filters
      },
      () => this.getLivestocks()
    );
  }
  filtersClose() {
    this.setState({ filters_open: false });
  }
  async linkLabels() {
    let response = await axios.post('/labels/bulk/animals', {
      animal_ids: this.state.selected_animals_unlinked.concat(
        this.state.selected_animals_linked
      ),
      label_ids: this.state.selected_labels_add.map((x) => x.value)
    });
    if (response.status == 200) {
      this.toggleModal('label_modal');
    }
  }
  setDeleteTags() {
    let selected = this.state.selected_animals;
    this.setState({
      ...this.state,
      delete_animals: selected
    });
    this.toggleModal('delete_modal');
  }
  capitalize(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  }
  addMeasures = () => {
    let measureForIds = [];
    this.state.selected_animals.map((x) => {
      let row = this.state.animals.filter((y) => y.id == x);
      if (row.length) {
        measureForIds.push({
          id: row[0]['id'],
          identifier: row[0]['identifier'],
          breed: row[0]['breed'] ? row[0]['breed']['display_name'] || '' : '',
          colour: row[0]['colour'],
          management_id: row[0]['eartag_management_id'],
          sex: row[0]['sex']
        });
      }
    });
    localStorage.setItem('measureForIds', JSON.stringify(measureForIds));
    this.props.history.push('/measures');
  };
  addBreeding = () => {
    let breedingForIds = [];
    this.state.selected_animals.map((x) => {
      let row = this.state.animals.filter((y) => y.id == x);
      if (row.length) {
        breedingForIds.push({
          id: row[0]['id'],
          identifier: row[0]['identifier'],
          breed: row[0]['breed'] ? row[0]['breed']['display_name'] || '' : '',
          colour: row[0]['colour'],
          management_id: row[0]['eartag_management_id'],
          sex: row[0]['sex']
        });
      }
    });
    localStorage.setItem('breedingForIds', JSON.stringify(breedingForIds));
    this.props.history.push('/breeding');
  };
  addTreatments = () => {
    let treatmentForIds = [];
    this.state.selected_animals.map((x) => {
      let row = this.state.animals.filter((y) => y.id == x);
      if (row.length) {
        treatmentForIds.push({
          id: row[0]['id'],
          identifier: row[0]['identifier'],
          breed: row[0]['breed'] ? row[0]['breed']['display_name'] || '' : '',
          colour: row[0]['colour'],
          management_id: row[0]['eartag_management_id'],
          sex: row[0]['sex']
        });
      }
    });
    localStorage.setItem('treatmentForIds', JSON.stringify(treatmentForIds));
    this.props.history.push('/teatments');
  };

  async downloadList() {
    let livestockIds =
      this.state.selected_animals.length > 0
        ? this.state.selected_animals
            .map((x) => {
              return x;
            })
            .join(',')
        : '';
    let url = '/animals/export';
    if (livestockIds) {
      url = `/animals/export?livestock_ids=${livestockIds}`;
    }
    const response = await axios.get(url, {
      responseType: 'blob'
    });
    downloadCSV(
      response.data,
      `Livestock_${moment().format(DATE_FORMAT.DATE)}.xlsx`
    );
  }

  async downloadExample() {
    const url = '/animals/template/export';
    const response = await axios.get(url, {
      responseType: 'blob'
    });

    downloadCSV(response.data, `Livestock_Example.xlsx`);
  }

  onClickEdit() {
    this.setState({
      editableIds: [...this.state.selected_animals]
    });
  }

  handleUpdateTableData(updatedData) {
    this.setState({
      tableData: [...updatedData]
    });
  }

  async onClickSaveEdit() {
    const ids = this.state.editableIds;
    const updatedData = this.state.tableData.filter((item) =>
      ids.includes(item.id)
    );
    const additionalData = {
      farms: this.state.farms
    };
    const payload = {
      animals: formatAnimalsPayload(updatedData, additionalData)
    };
    try {
      await AnimalService.updateAnimals(payload);
      this.getLivestocks();
      this.setState({
        editableIds: [],
        selected_animals: [],
        selected_animals_linked: [],
        selected_animals_unlinked: []
      });
    } catch (error) {
      console.error(error);
    }
  }

  onClickCancelEdit() {
    this.setState({
      editableIds: [],
      tableData: [...this.state.actualData]
    });
  }

  showAnimalPopup(key, liveStock) {
    this.setState((state) => ({
      ...state,
      showPopup: true,
      animal_popup_field: key || null,
      filtered_popup_animals: liveStock || []
    }));
  }

  onAnimalSelect(field, value) {
    if (field === 'sire_id') field = 'sireId';
    if (field === 'dam_id') field = 'damId';
    this.handleTableValueChange(field, value, this.state.tempModalValue);
    this.closeAnimalPopup();
  }

  closeAnimalPopup() {
    this.setState((state) => ({
      ...state,
      showPopup: false,
      animal_popup_field: null,
      filtered_popup_animals: [],
      tempModalValue: null
    }));
  }

  render() {
    const columns = [
      {
        id: 'select',
        accessor: (d) => {
          return { id: d.id, tag: d.tag };
        },
        headerClassName: 'wordwrap',
        sortable: false,
        Header: (cellinfo) => (
          <div className="position-relative form-check">
            <SelectionPopover
              isOpen={this.state.isOpenSelectionPopover}
              onClickOnThisPage={this.onClickOnThisPage}
              onClickAllRecords={this.onClickAllRecords}
              setOpen={(isOpen) =>
                this.setState({
                  isOpenSelectionPopover:
                    isOpen !== undefined
                      ? isOpen
                      : !this.state.isOpenSelectionPopover
                })
              }
              content={
                <input
                  type="checkbox"
                  defaultChecked={this.state.selectAll}
                  onChange={() => {
                    this.onToggleSelectionPopover();
                    if (this.state.selectAll) {
                      this.setState({
                        selectAll: !this.state.selectAll
                      });
                      this.setState((state) => ({
                        ...state,
                        selected_animals: []
                      }));
                    }
                  }}
                />
              }
            />
          </div>
        ),
        Cell: (props) => {
          return (
            <FormGroup check>
              <Input
                type="checkbox"
                defaultChecked={this.state.selected_animals.includes(
                  props.value.id
                )}
                value={props.value.id}
                onChange={(e) =>
                  this.onLivestockSelect(
                    parseInt(e.target.value),
                    props.value.tag ? 'linked' : 'unlinked'
                  )
                }
                key={props.value.id}
              />
            </FormGroup>
          );
        },
        filterable: false,
        maxWidth: 85
      },
      {
        id: 'identifier',
        minWidth: 120,
        Header: 'Livestock ID',
        accessor: (d) => {
          return { id: d.id, identifier: d.identifier };
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier);
        },
        Cell: (props) => (
          <a
            href="#"
            onClick={() => this.props.history.push('/animal/' + props.value.id)}
          >
            {props.value.identifier}
          </a>
        )
      },
      {
        Header: 'Mgmt Tag ID',
        id: 'eartag_management_id',
        accessor: (d) => {
          return d.eartag_management_id.indexOf('mgmt~tmp~') > -1
            ? ''
            : d.eartag_management_id;
        },
        headerClassName: 'wordwrap',
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        },
        minWidth: 160
      },
      {
        Header: 'Sex',
        accessor: (d) => {
          return (d.sex?.label ? d.sex.label : d.sex) ?? '';
        },
        id: 'sex',
        headerClassName: 'wordwrap',
        Cell: (props) => {
          return this.capitalize(props.value);
        },
        editConfig: {
          type: EDIT_COMPONENT_TYPE.SELECT,
          options: SELECT_OPTIONS.GENDER
        },
        minWidth: 140
      },
      {
        Header: 'Colour',
        accessor: (data) => {
          return data.colour ?? '';
        },
        id: 'colour',
        headerClassName: 'wordwrap',
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        },
        minWidth: 140
      },
      {
        Header: 'Breed',
        id: 'breedName',
        accessor: (data) => {
          return (
            (data.breedName?.label ? data.breedName.label : data.breedName) ??
            ''
          );
        },
        headerClassName: 'wordwrap',
        minWidth: 120,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.SELECT,
          options: this.state.breed_opts
        }
      },
      {
        Header: 'Kraal Tag ID',
        id: 'tagIdentifier',
        accessor: (d) => {
          return d.tagIdentifier || '';
        },
        headerClassName: 'wordwrap',
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT,
          hasCustomCell: true,
          customCell: (cellProps) => {
            return cellProps.original?.tag?.id ? (
              <a
                href="#"
                onClick={() =>
                  this.props.history.push('/tag/' + cellProps.original?.tag?.id)
                }
              >
                {cellProps.value}
              </a>
            ) : (
              'Unlinked'
            );
          }
        },
        minWidth: 150
      },
      {
        Header: 'Brand',
        accessor: 'brand',
        id: 'brand',
        headerClassName: 'wordwrap',
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        }
      },
      {
        Header: 'Farm',
        id: 'farm',
        accessor: 'farmName',
        headerClassName: 'wordwrap',
        minWidth: 150,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.SELECT,
          options: this.state.farm_opts,
          valueKey: 'id'
        }
      },
      {
        Header: 'Assigned Geofence',
        id: 'assignedGeofence',
        accessor: (d) => {
          const geofence = d?.geofences?.filter((a) => !a.is_master)?.[0];
          return {
            identifier: geofence?.identifier ?? '',
            name: geofence?.name ?? '',
            id: geofence?.id ?? ''
          };
        },
        headerClassName: 'wordwrap',
        Cell: (cellProps) => {
          if (this.state.editableIds.includes(cellProps.original.id)) {
            const currentFarmId =
              this.state.tableData?.[cellProps.index]?.farm?.id;
            const currentGeofence = this.state.tableData?.[
              cellProps.index
            ]?.geofences?.find((g) => !g.is_master);
            const currentFarm = this.state.farms?.find(
              (i) => i.id === currentFarmId
            );
            const farmGeofences = currentFarm?.geofences.filter(
              (g) => !g.is_master
            );
            let geofenceValue;
            if (farmGeofences.find((g) => g?.id === currentGeofence?.id)) {
              geofenceValue = {
                ...currentGeofence,
                value: currentGeofence.value,
                label: currentGeofence.name
              };
            }
            const geofencesOptions = farmGeofences?.map((g) => {
              return { value: g.id, label: g.name };
            });

            return (
              <Select
                value={geofenceValue ?? {}}
                options={geofencesOptions}
                onChange={(e) => {
                  let data = [...this.state.tableData];
                  let geofence = farmGeofences.find((g) => g.id === e.value);
                  data[cellProps.index].geofences = [geofence];
                  this.setState({ tableData: data, isGeofenceChanged: true });
                }}
              />
            );
          }
          return (
            <a
              href="#"
              onClick={() =>
                this.props.history.push('geofence/' + cellProps.value.id)
              }
            >
              {cellProps.value.name}
            </a>
          );
        },
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier);
        },
        filterMethod: (cellProps, row) => {
          if (cellProps.value === null) {
            return true;
          } else {
            return row.assignedGeofence?.name
              ?.toLowerCase()
              ?.includes(cellProps.value?.toLowerCase());
          }
        },
        minWidth: 150
      },
      {
        Header: 'Livestock Type',
        id: 'livestockType',
        accessor: (d) => {
          return (
            (d.livestockType?.label
              ? d.livestockType.label
              : d.livestockType) ?? ''
          );
        },
        headerClassName: 'wordwrap',
        minWidth: 150,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.SELECT,
          options: this.state.type_opts,
          hasCustomCell: true,
          customCell: (cellProps) => {
            return capitalize(cellProps?.value);
          }
        }
      },
      {
        Header: 'Age',
        id: 'age',
        accessor: (d) => {
          let value = '';
          if (
            d.dob_at &&
            d.dob_at != '' &&
            d.dob_at != '0000-00-00 00:00:00' &&
            !d.dob_at.includes('d.dob_at')
          ) {
            let b = moment(d.dob_at);
            let a = moment();
            let years = a.diff(b, 'year');
            b.add(years, 'years');
            let months = a.diff(b, 'months');
            b.add(months, 'months');
            let days = a.diff(b, 'days');
            value = years + 'y ' + months + 'm ' + days + 'd';
            return value;
          } else {
            return '';
          }
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
        minWidth: 150
      },
      {
        Header: 'Weight',
        accessor: 'weight',
        id: 'weight',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Breeding Status',
        id: 'breedingStatus',
        accessor: 'breeding_status',
        headerClassName: 'wordwrap',
        minWidth: 160
      },
      {
        id: 'calfLivestockId',
        Header: 'Calf ID',
        accessor: (d) => {
          return {
            id: d.calf_livestock_id,
            identifier: d.calf_livestock_identifier
          };
        },
        headerClassName: 'wordwrap',
        Cell: (props) => {
          return props.value.id != '' ? (
            <a
              href="#"
              onClick={() =>
                this.props.history.push('/animal/' + props.value.id)
              }
            >
              {props.value.identifier}
            </a>
          ) : (
            ''
          );
        },
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.calf_livestock_identifier);
        },
        minWidth: 150
      },
      {
        Header: 'Calf Mgmt Tag ID',
        id: 'calfMgmtId',
        accessor: 'calf_mgmt_tag_id',
        headerClassName: 'wordwrap',
        minWidth: 150
      },
      {
        id: 'calfAge',
        Header: 'Calf Age',
        accessor: (d) => {
          let value = '';
          if (
            d.calf_age &&
            d.calf_age != '' &&
            d.calf_age != '0000-00-00 00:00:00' &&
            !d.calf_age.includes('d.calf_age')
          ) {
            let b = moment(d.calf_age);
            let a = moment();
            let years = a.diff(b, 'year');
            b.add(years, 'years');
            let months = a.diff(b, 'months');
            b.add(months, 'months');
            let days = a.diff(b, 'days');
            value = years + 'y ' + months + 'm ' + days + 'd';
            return value;
          } else {
            return '';
          }
        },
        headerClassName: 'wordwrap',
        minWidth: 150,
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        }
      },
      {
        Header: 'Connection Status',
        id: 'connectionStatus',
        headerClassName: 'wordwrap',
        accessor: (d) => {
          return d?.tag?.status;
        },
        sortMethod: (a, b) => {
          return a?.localeCompare(b);
        },
        Cell: (props) => {
          const value = props.original?.tag?.status;
          const id = props.original?.id;

          if (value) {
            return (
              <div>
                <StatusIndicator key={id} status={value} />
                {capitalize(value)}
              </div>
            );
          }
          return <></>;
        },
        minWidth: 150
      },
      {
        Header: 'Last Reading',
        id: 'lastReading',
        accessor: (item) => {
          return item?.tag?.timestamp_at ? item?.tag?.timestamp_at : null;
        },
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        },
        Cell: (v) => {
          const lastReadingTime = v?.original?.tag?.timestamp_at;
          return lastReadingTime
            ? moment(lastReadingTime).format(DATE_FORMAT.DATETIME)
            : null;
        },
        minWidth: 170
      },
      {
        Header: 'Official Tag ID',
        id: 'officialTagId',
        accessor: 'eartag_official_id',
        headerClassName: 'wordwrap',
        minWidth: 150,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        }
      },
      {
        Header: 'Ear Mark',
        id: 'earMark',
        accessor: 'ear_mark',
        headerClassName: 'wordwrap',
        minWidth: 150,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        }
      },
      {
        Header: 'Specie',
        id: 'specieName',
        accessor: (d) => {
          return (
            (d.specieName?.label ? d.specieName.label : d.specieName) ?? ''
          );
        },
        headerClassName: 'wordwrap',
        minWidth: 150,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.SELECT,
          options: SELECT_OPTIONS.SPECIE
        }
      },
      {
        Header: 'DoB',
        id: 'dob',
        accessor: 'dob',
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          if (!a) return -1;
          return moment(b).format('x') - moment(a).format('x');
        },
        minWidth: 170,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.DATE_PICKER
        }
      },
      {
        Header: 'Polled/Horned',
        accessor: (d) => {
          return (
            (d.polledHorned?.label ? d.polledHorned.label : d.polledHorned) ??
            ''
          );
        },
        id: 'polledHorned',
        headerClassName: 'wordwrap',
        minWidth: 150,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.SELECT,
          options: SELECT_OPTIONS.POLLED_HORNED
        }
      },
      {
        Header: 'Description',
        accessor: 'name',
        id: 'description',
        headerClassName: 'wordwrap',
        minWidth: 150,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        }
      },
      {
        Header: 'Sire ID',
        accessor: 'sireId',
        id: 'sireId',
        Cell: (cellProps) => {
          let animalId =
            this.state.tableData?.[cellProps?.index]?.[cellProps?.column?.id];
          let tempVal = this.state?.maleLivestocks?.filter(
            (s) => s.id === animalId
          );
          let animal = tempVal.length ? tempVal[0].identifier : '';
          let isEditable = this.state.editableIds.includes(
            cellProps.original.id
          );
          if (isEditable && !animal) {
            animal = 'Select';
          }
          return (
            <div>
              <a
                href="#"
                onClick={(e) => {
                  if (animal === 'Select') {
                    this.setState({
                      tempModalValue: cellProps
                    });
                    this.showAnimalPopup('sire_id', this.state.maleLivestocks);
                  } else this.props.history.push(`/animal/${animalId}`);
                }}
              >
                {animal}
              </a>
              {animal !== 'Select' && isEditable && (
                <span
                  className="float-right rg-pointer"
                  onClick={(e) => {
                    this.handleTableValueChange('sireId', '', cellProps);
                  }}
                >
                  clear
                </span>
              )}
            </div>
          );
        },
        headerClassName: 'wordwrap',
        minWidth: 150
      },
      {
        Header: 'Dam ID',
        accessor: 'damId',
        id: 'damId',
        Cell: (cellProps) => {
          let animalId =
            this.state.tableData?.[cellProps?.index]?.[cellProps?.column?.id];
          let tempVal = this.state?.femaleLivestocks?.filter(
            (s) => s.id === animalId
          );
          let animal = tempVal.length ? tempVal[0].identifier : '';
          let isEditable = this.state.editableIds.includes(
            cellProps.original.id
          );
          if (isEditable && !animal) {
            animal = 'Select';
          }
          return (
            <div>
              <a
                href="#"
                onClick={(e) => {
                  if (animal === 'Select') {
                    this.setState({
                      tempModalValue: cellProps
                    });
                    this.showAnimalPopup('dam_id', this.state.maleLivestocks);
                  } else this.props.history.push(`/animal/${animalId}`);
                }}
              >
                {animal}
              </a>
              {animal !== 'Select' && isEditable && (
                <span
                  className="float-right rg-pointer"
                  onClick={(e) => {
                    this.handleTableValueChange('damId', '', cellProps);
                  }}
                >
                  clear
                </span>
              )}
            </div>
          );
        },
        headerClassName: 'wordwrap',
        minWidth: 150
      },
      {
        Header: 'Number of calves',
        accessor: 'numbers_calves',
        id: 'numbersCalves',
        headerClassName: 'wordwrap',
        minWidth: 100
      },
      {
        Header: 'Purchase Date',
        id: 'purchaseDate',
        accessor: 'purchaseDate',
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          if (!a) return -1;
          return moment(b).format('x') - moment(a).format('x');
        },
        editConfig: {
          type: EDIT_COMPONENT_TYPE.DATE_PICKER
        },
        minWidth: 170
      },
      {
        Header: 'Purchase From',
        accessor: 'purchasedFrom',
        id: 'purchasedFrom',
        headerClassName: 'wordwrap',
        minWidth: 100,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        }
      },
      {
        Header: 'Purchase Where',
        accessor: 'purchasedWhere',
        id: 'purchasedWhere',
        headerClassName: 'wordwrap',
        minWidth: 100,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        }
      },
      {
        Header: 'Purchase Notes',
        accessor: 'purchaseNotes',
        id: 'purchaseNotes',
        headerClassName: 'wordwrap',
        minWidth: 100,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        }
      },
      {
        Header: 'Date Sold',
        id: 'dateSold',
        accessor: 'date_sold',
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          if (!a) return -1;
          return moment(b).format('x') - moment(a).format('x');
        },
        editConfig: {
          type: EDIT_COMPONENT_TYPE.DATE_PICKER
        },
        minWidth: 170
      },
      {
        Header: 'Sold To',
        id: 'soldTo',
        accessor: 'sold_to',
        headerClassName: 'wordwrap',
        minWidth: 100,
        sortMethod: (a, b) => {
          return a?.localeCompare(b);
        },
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        }
      },
      {
        Header: 'Loss Date',
        id: 'lossDate',
        accessor: 'loss_date',
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        },
        minWidth: 170,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.DATE_PICKER
        }
      },
      {
        Header: 'Loss Reason',
        accessor: 'loss_reason',
        id: 'lossReason',
        headerClassName: 'wordwrap',
        minWidth: 150,
        sortMethod: (a, b) => {
          return a?.localeCompare(b);
        },
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        }
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        id: 'comments',
        headerClassName: 'wordwrap',
        minWidth: 150,
        editConfig: {
          type: EDIT_COMPONENT_TYPE.INPUT
        }
      }
    ];

    const tableColumns = this.props?.user?.isAdmin
      ? columns
      : getTableColumns(
          columns,
          this.props.subscription?.myPlan?.type,
          FEATURE_LIST.LIVESTOCK_LIST
        );
    return (
      <div>
        <Filters
          farms={this.state.filters.farms}
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm', 'geofence', 'label']}
        ></Filters>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="mb-0">Livestock</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Livestock</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>
                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg="6"
                  className="d-flex justify-content-end align-items-center h-100"
                >
                  <InputGroup className="head-search mr-2" size="sm">
                    <Input
                      type="search"
                      id="search"
                      name="query"
                      defaultValue={this.state.query}
                      onChange={(e) => this.onChange(e.target.value, 'query')}
                      placeholder="Search Livestock"
                      onKeyPress={(e) => this.handleKeyPress(e.charCode)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        onClick={() => this.handleKeyPress(13)}
                        className="rg-pointer"
                      >
                        <i className="fas fa-search"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {this.state.editableIds.length ? (
                    <div>
                      <Button
                        color="primary"
                        className="float-right"
                        onClick={() => {
                          if (this.state.isGeofenceChanged) {
                            this.setState({
                              isOpenConfirmationModal: true
                            });
                          } else {
                            this.onClickSaveEdit();
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        className="outline float-right"
                        onClick={() => this.onClickCancelEdit()}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <>
                      <ButtonDropdown
                        className="float-right"
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleButton}
                      >
                        <DropdownToggle caret>Actions</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            color="primary"
                            className="float-right"
                            onClick={() =>
                              this.props.history.push('/animal_new')
                            }
                          >
                            {' '}
                            Add livestock
                          </DropdownItem>

                          {!!this.state.selected_animals.length && (
                            <DropdownItem onClick={() => this.onClickEdit()}>
                              Edit livestock
                            </DropdownItem>
                          )}

                          <DropdownItem
                            color="primary"
                            onClick={() => {
                              this.toggleModal('csv_modal');
                            }}
                          >
                            {' '}
                            Upload livestock
                          </DropdownItem>
                          <DropdownItem
                            color="primary"
                            onClick={() => this.downloadList()}
                          >
                            Download livestock list
                          </DropdownItem>
                          {this.state.selected_animals.length > 0 && (
                            <React.Fragment>
                              <DropdownItem
                                onClick={() => this.setDeleteTags()}
                              >
                                Archive livestock
                              </DropdownItem>
                            </React.Fragment>
                          )}
                        </DropdownMenu>
                      </ButtonDropdown>
                      <Button
                        color="primary"
                        className="float-right mr-2"
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            filters_open: !this.state.filters_open
                          })
                        }
                      >
                        <i className="fa fa-filter"></i>Filters
                      </Button>
                    </>
                  )}
                </Col>
              </Row>

              <Col xs="12" md="12" lg="12">
                <CustomTable
                  data={[...this.state.tableData]}
                  columns={tableColumns}
                  editableIds={this.state.editableIds}
                  updateTableData={this.handleUpdateTableData}
                />
              </Col>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive livestocks</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive selected livestocks? This action
            cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteTag()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.link_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Linking confirmation</b>
            </h5>
            <br />
            <br />
            <br />
            <p className="text-center">
              Tag <b>{this.getLabel(this.state.selected_tags[0])}</b> will be
              linked to
            </p>
            <Livestock
              animals={this.state.animals}
              animal={this.state.selected_animals_unlinked}
            ></Livestock>
            <Button
              className="float-right"
              color="secondary"
              onClick={() =>
                this.props.history.push(
                  '/animal/' + this.state.selected_animals_unlinked
                )
              }
            >
              View Livestock
            </Button>
            <Button
              className="float-right"
              color="primary"
              onClick={() => this.props.history.push('/tracking')}
            >
              Go to Tracking
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('link_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.linkTag()}>
              Link
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.csv_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>XLSX Upload</b>
            </h5>
            <br />
            <br />

            <div>
              You can download the livestock list to input new livestocks.
            </div>
            <div>
              Download and save the file and then choose this file to upload new
              animals.
            </div>
            <br />
            <FormGroup>
              <Label>Select farm</Label>
              <Select
                value={this.state.csv_farm}
                onChange={this.handleCSVFarmChange}
                options={this.state.farm_opts}
              />
            </FormGroup>
            <FormGroup>
              <Label>XLSX file</Label>
              <Input type="file" onChange={this.handleCSVChange}></Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('csv_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.uploadCSV()}>
              Upload
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.label_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Link Groups</b>
            </h5>
            <br />
            <br />
            <br />
            <br />
            <FormGroup row>
              <Label sm="4">Livestock Group</Label>
              <Col sm="8">
                <Select
                  value={this.state.selected_labels_add}
                  onChange={this.handleLabelAddChange}
                  options={this.state.label_opts}
                  isMulti={true}
                  isSearchable={true}
                />
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('label_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.linkLabels()}>
              Link Groups
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.sucess_modal}
          className={this.props.className}
        >
          <ModalBody>
            <h4>{this.state.uploadMessage}</h4>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.toggleModal('sucess_modal')}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
        {this.state.showPopup && (
          <AnimalPopup
            key="animal-popup"
            fieldKey={this.state.animal_popup_field}
            animals={this.state.filtered_popup_animals}
            onSelect={this.onAnimalSelect}
            onClose={this.closeAnimalPopup}
          />
        )}

        <ConfirmationModal
          isOpen={this.state.isOpenConfirmationModal}
          description={ANIMAL_ERRORS.ASSIGNED_TO_DIFFERENT_GEOGENCE}
          onCancel={() => {
            this.setState({ isOpenConfirmationModal: false });
          }}
          onSubmit={() => {
            this.onClickSaveEdit();
            this.setState({
              isOpenConfirmationModal: false
            });
          }}
          submitText={'Yes'}
          submitButtonType={'primary'}
        />
      </div>
    );
  }
}
export default connect((state) => state)(Livestocks);
