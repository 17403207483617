import React from 'react';
import {
  Row,
  Col,
  Card,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import * as XLSX from 'xlsx';
import Breedings from '../../components/Breeding';
import Filters from '../../components/filters/index.jsx';
import UploadModal from '../../components/Modals/UploadModal';
import { BREEDING_CSV_TEMPLATE } from '../../constants/templates';
import axios from '../../axios';
import moment from 'moment';
import { downloadCSV } from '../../helpers/common';
import { errorToastHandler } from '../../components/action_notifier';
import messages from '../../constants/messages';
import { DATE_FORMAT } from '../../constants/common';

class Breeding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      isTabInAction: false,
      tab_action: '',
      tabType: 'breeding',
      filters_open: false
    };
    this.toggleButton = this.toggleButton.bind(this);
    this.addNewTabRows = this.addNewTabRows.bind(this);
    this.clearTabOperation = this.clearTabOperation.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.filtersClose = this.filtersClose.bind(this);

    this.breedingTab = React.createRef();
  }
  toggleButton() {
    this.setState((state) => ({
      dropdownOpen: !state.dropdownOpen
    }));
  }
  tab_addAction() {
    let tabRef = this.breedingTab;
    if (!Object.keys(tabRef.current.sigTblRef.current.state.editApply).length) {
      errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
    } else if (
      Object.keys(tabRef.current.sigTblRef.current.state.editApply).length === 1
    ) {
      tabRef.current.addAction();
      this.setState({
        isTabInAction: !this.state.isTabInAction,
        tab_action: 'add'
      });
    } else if (
      Object.keys(tabRef.current.sigTblRef.current.state.editApply).length > 1
    ) {
      tabRef.current.toggleModal('bulk_modal');
    }
  }
  tab_editAction() {
    let tabRef = this.breedingTab;
    if (Object.keys(tabRef.current.sigTblRef.current.state.editApply).length) {
      tabRef.current.editAction();
      this.setState({
        isTabInAction: !this.state.isTabInAction,
        tab_action: 'edit'
      });
    } else {
      errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
    }
  }
  tab_deleteAction() {
    this.breedingTab.current.deleteAction();
  }
  tab_saveAction() {
    this.breedingTab.current.saveAction();
  }
  tab_cancelAction() {
    this.breedingTab.current.cancelAction();
    this.setState({
      isTabInAction: !this.state.isTabInAction,
      tab_action: this.state.tab_action
    });
  }
  addNewTabRows() {
    this.setState({
      isTabInAction: !this.state.isTabInAction,
      tab_action: 'add'
    });
  }
  clearTabOperation() {
    this.setState({
      isTabInAction: !this.state.isTabInAction,
      tab_action: this.state.tab_action
    });
  }
  filtersUpdated(filters) {
    this.breedingTab.current.filterData(filters);
  }
  filtersClose() {
    this.setState((state) => ({
      ...state,
      filters_open: false
    }));
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  handleCSVChange(event) {
    this.setState({
      uploading: true
    });
    let reader = new FileReader();
    const isXLSX = event.target.files?.[0]?.name?.includes('.xlsx');
    if (!isXLSX) {
      reader.readAsText(event.target.files[0]);
    }
    const rABS = !!reader.readAsBinaryString;
    reader.onload = function (e) {
      let data = '';
      if (isXLSX) {
        let wb = XLSX.read(e.target.result, {
          type: rABS ? 'binary' : 'array'
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const csv = XLSX.utils.sheet_to_csv(ws, { strip: true });
        data = csv;
      } else {
        data = reader.result;
      }
      this.setState({
        csvfile: data,
        uploading: false
      });
    }.bind(this);
    if (isXLSX) {
      if (rABS) reader.readAsBinaryString(event.target.files[0]);
      else reader.readAsArrayBuffer(event.target.files[0]);
    }

    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  }
  tab_uploadAction() {
    this.toggleModal('csv_modal');
  }
  async uploadCSV() {
    try {
      let csv = this.state.csvfile;
      await axios.post('breeding/uploadCsv', {
        csv: csv
      });
      this.toggleModal('csv_modal');
      this.breedingTab.current.getData();
    } catch (error) {
      console.error(error);
    }
  }
  async downloadCSV() {
    if (this.breedingTab?.current?.state?.selectedIds?.length) {
      try {
        const query = `breeding_ids=${this.breedingTab?.current?.state?.selectedIds}`;
        const response = await axios.get(`/breeding/export?${query}`, {
          responseType: 'blob'
        });
        downloadCSV(
          response.data,
          `Breeding_${moment().format(DATE_FORMAT.DATE)}.xlsx`
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {
    const upload = () => {
      this.uploadCSV();
    };
    const handle = (e) => {
      this.handleCSVChange(e);
    };
    return (
      <div>
        <Filters
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm', 'geofence', 'label']}
        ></Filters>
        <UploadModal
          filename="breeding-template.csv"
          isOpen={this.state.csv_modal}
          onUpload={upload}
          handleCSVChange={handle}
          template={BREEDING_CSV_TEMPLATE}
          onCancel={() => this.toggleModal('csv_modal')}
          description={
            <>
              <div>
                You can download the breeding list to input new breeding events.
              </div>
              <div>
                Download and save the file and then choose this file to upload
                new breeding events.
              </div>
            </>
          }
        />
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6" className="listing-heading">
                  <h4 className="mb-0">Livestock breeding</h4>
                  <Breadcrumb>
                    <BreadcrumbItem>List of Livestock breeding</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg="6"
                  className="d-flex justify-content-end align-items-center h-100"
                >
                  <InputGroup className="head-search mr-2" size="sm">
                    <Input
                      type="search"
                      id="search-measure"
                      name="query"
                      defaultValue={this.state.query}
                      onChange={(e) =>
                        this.breedingTab.current.keywordSearch(
                          e.target.value,
                          'query'
                        )
                      }
                      placeholder="Search breeding"
                      onKeyPress={(e) =>
                        this.breedingTab.current.handleKeyPress(e.charCode)
                      }
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        onClick={() =>
                          this.breedingTab.current.handleKeyPress(13)
                        }
                        className="rg-pointer"
                      >
                        <i className="fas fa-search"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {!this.state.isTabInAction && (
                    <ButtonDropdown
                      className="float-right"
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggleButton}
                    >
                      <DropdownToggle caret className="">
                        Actions
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => this.tab_addAction()}>
                          Add {this.state.tabType}
                        </DropdownItem>
                        <DropdownItem onClick={() => this.tab_editAction()}>
                          Edit {this.state.tabType}
                        </DropdownItem>
                        <DropdownItem onClick={() => this.tab_uploadAction()}>
                          Upload {this.state.tabType}
                        </DropdownItem>
                        {this.breedingTab?.current?.state?.selectedIds
                          ?.length ? (
                          <DropdownItem
                            color="primary"
                            onClick={() => this.downloadCSV()}
                          >
                            Download breeding list
                          </DropdownItem>
                        ) : null}
                        {this.breedingTab?.current?.state?.selectedIds ? (
                          <DropdownItem onClick={() => this.tab_deleteAction()}>
                            Archive {this.state.tabType}
                          </DropdownItem>
                        ) : null}
                      </DropdownMenu>
                    </ButtonDropdown>
                  )}
                  <Button
                    color="primary"
                    className="float-right mr-2"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        filters_open: !this.state.filters_open
                      })
                    }
                  >
                    <i className="fa fa-filter"></i>Filters
                  </Button>
                  {this.state.isTabInAction && (
                    <div>
                      <Button
                        color="primary"
                        className="float-right"
                        onClick={() => this.tab_saveAction()}
                      >
                        Save
                      </Button>
                      <Button
                        className="outline float-right"
                        onClick={() => this.tab_cancelAction()}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Breedings
                    ref={this.breedingTab}
                    type="list"
                    // animal_id={this.props.match.params.id}
                    tab_action={this.state.tab_action}
                    addNewTabRows={this.addNewTabRows}
                    clearTabOperation={this.clearTabOperation}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Breeding;
