import React, { memo, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import ReactTable from 'react-table';
import Select from 'react-select';
import moment from 'moment';

import { DATE_FORMAT, EDIT_COMPONENT_TYPE } from '../../constants/common';
import { filterCaseInsensitive } from '../../helpers/filter';

const EditableInput = ({ value, onChange, ...props }) => {
  const defaultProps = {
    className: 'form-control',
    type: 'text'
  };
  return (
    <input value={value} onChange={onChange} {...defaultProps} {...props} />
  );
};

const EditableSelect = ({ value, options, onChange, isMulti = false }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
    />
  );
};

const EditableDatePicker = ({ onChange, ...props }) => {
  const defaultProps = {
    className: 'form-control',
    dateFormat: 'dd/MM/yyyy'
  };
  return (
    <DatePicker
      className="form-control"
      onChange={onChange}
      {...defaultProps}
      {...props}
    />
  );
};

const CustomTable = ({
  data,
  columns,
  editableIds = [],
  updateTableData,
  dependencies = [],
  ...props
}) => {
  const handleInputChange = (cellProps, newValue) => {
    let updatedData = [...data];
    updatedData[cellProps.index][cellProps.column.id] = newValue;
    updateTableData([...updatedData]);
  };

  const formattedColumns = useMemo(() => {
    let updatedColumns = [];
    columns?.forEach((column) => {
      if (column.editConfig?.type === EDIT_COMPONENT_TYPE.INPUT) {
        column.Cell = (cellProps) => {
          const value = data?.[cellProps?.index]?.[cellProps?.column?.id];

          if (editableIds?.includes(cellProps.original.id)) {
            return (
              <EditableInput
                value={value}
                onChange={(e) => handleInputChange(cellProps, e.target.value)}
              />
            );
          }
          if (column.editConfig?.hasCustomCell)
            return column.editConfig.customCell(cellProps);
          return cellProps.value;
        };
      }

      if (column.editConfig?.type === EDIT_COMPONENT_TYPE.SELECT) {
        column.Cell = (cellProps) => {
          const value = data?.[cellProps?.index]?.[cellProps?.column?.id];

          if (editableIds?.includes(cellProps.original.id)) {
            const checkValue = column.editConfig.valueKey
              ? value[column.editConfig.valueKey]
              : value;
            return (
              <EditableSelect
                options={column.editConfig.options}
                value={column.editConfig.options.find(
                  (i) => i.value === checkValue || i.label === checkValue
                )}
                onChange={(e) => handleInputChange(cellProps, e)}
              />
            );
          }
          if (column.editConfig?.hasCustomCell)
            return column.editConfig.customCell(cellProps);
          return cellProps.value;
        };
      }

      if (column.editConfig?.type === EDIT_COMPONENT_TYPE.DATE_PICKER) {
        column.Cell = (cellProps) => {
          let value = data?.[cellProps?.index]?.[cellProps?.column?.id];
          if (editableIds?.includes(cellProps.original.id)) {
            let dateProps = {};

            if (value) {
              const formattedValue = moment(value).format(DATE_FORMAT.DATE)
              const momentObject = moment(formattedValue, DATE_FORMAT.DATE);
              const dateObject = momentObject.toDate();
              dateProps.selected = dateObject;
            }

            return (
              <EditableDatePicker
                {...dateProps}
                onChange={(e) =>
                  handleInputChange(cellProps, moment(e).format())
                }
              />
            );
          }

          if (column.editConfig?.hasCustomCell)
            return column.editConfig.customCell(cellProps);

          const formattedValue = value
            ? moment(value).format(DATE_FORMAT.DATE)
            : '';
          return formattedValue;
        };
      }

      updatedColumns.push(column);
    });
    return updatedColumns;
  }, [editableIds, columns, ...dependencies]);

  const defaultProps = {
    showPagination: data?.length > 0,
    minRows: 0,
    resizable: true,
    defaultPageSize: 25,
    filterable: true,
    defaultFilterMethod: filterCaseInsensitive
  };

  return (
    <div className="allow-overflow">
      <ReactTable
        data={[...data]}
        columns={formattedColumns ?? []}
        {...defaultProps}
        {...props}
      />
    </div>
  );
};

export default memo(CustomTable);
