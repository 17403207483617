import React from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import moment from 'moment';
import ReactTable from 'react-table';
import Select from 'react-select';

import axios from '../../axios';
import StatusIndicator from '../../components/statusIndicator';
import default_animal from '../../assets/images/default-animal.jpg';
import Filters from '../../components/filters/index.jsx';
import SelectionPopover from '../../components/Popovers/SelectionPopover';
import { SocketContext } from '../../context/socket';
import { TagService } from '../../services';
import { DATE_FORMAT } from '../../constants/common';

function Animal(props) {
  let animalId = props.animal[0];
  let animal = props.animals.filter((a) => a.id == animalId)[0];
  let b = moment(animal.age);
  let a = moment();
  let years = a.diff(b, 'year');
  b.add(years, 'years');

  let months = a.diff(b, 'months');
  b.add(months, 'months');

  let days = a.diff(b, 'days');
  return (
    <div className="animal-box">
      <Row>
        <Col sm="12" md="12" lg="3">
          <img className="animal-profile-pic" src={default_animal} alt="" />
        </Col>
        <Col sm="12" md="12" lg="9">
          <Table responsive className="no-border">
            <tbody>
              <tr>
                <td>Official Tag ID</td>
                <td>
                  <span className="text-mute">{animal.eartag_official_id}</span>
                </td>
              </tr>
              <tr>
                <td>Diagri Tag ID</td>
                <td>
                  <span className="text-mute">{animal.id}</span>
                </td>
              </tr>
              <tr>
                <td>Management Tag ID</td>
                <td>
                  <span className="text-mute">{animal.eartag_official_id}</span>
                </td>
              </tr>
              <tr>
                <td>Livestock ID</td>
                <td>
                  <span className="text-mute">{animal.id}</span>
                </td>
              </tr>
              <tr>
                <td>Age</td>
                <td>
                  <span className="text-mute">
                    {years + ' years ' + months + ' months ' + days + ' days'}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Breed</td>
                <td>
                  <span className="text-mute">{animal.breed.display_name}</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

class Tags extends React.Component {
  static contextType = SocketContext;
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      editableIds: [],
      socket: null,
      activeTab: '1',
      delete_modal: false,
      add_modal: false,
      link_modal: false,
      csv_modal: false,
      tags: [],
      selected_tags_linked: [],
      selected_tags_unlinked: [],
      selected_tags: [],
      selected_animals: [],
      new_tag: {},
      query: '',
      isOpenSelectionPopover: false,
      tableData: [],
      animals: [],
      dropdownOpen: false,
      selected_farm: {},
      farm_opts: [],
      csvfile: '',
      uploading: false,
      selectAll: false,
      search_livestock: '',
      filters: {
        farms: [],
        geofences: [],
        labels: []
      },
      filters_open: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onAnimalSelect = this.onAnimalSelect.bind(this);
    this.getTags = this.getTags.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.linkTag = this.linkTag.bind(this);
    this.getAnimals = this.getAnimals.bind(this);
    this.setDeleteTag = this.setDeleteTag.bind(this);
    this.setDeleteTags = this.setDeleteTags.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.handleFarmChange = this.handleFarmChange.bind(this);
    this.getFarms = this.getFarms.bind(this);
    this.handleCSVChange = this.handleCSVChange.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.filtersClose = this.filtersClose.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
    this.onClickSaveEdit = this.onClickSaveEdit.bind(this);
    this.onClickCancelEdit = this.onClickCancelEdit.bind(this);
    this.editTags = this.editTags.bind(this);
  }
  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.tableData.map((val) => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_tags: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      this.state.tags.map((val) => {
        checkedCopy.push(val.id);
      });
    }
    this.setState((state) => ({
      ...state,
      selected_tags: checkedCopy
    }));
    this.setState({
      selectAll: newCheckboxValue
    });
    this.onToggleSelectionPopover();
  }
  onToggleSelectionPopover(isOpen) {
    const open =
      isOpen !== undefined
        ? isOpen
        : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({
      isOpenSelectionPopover: open
    });
  }
  handleCSVChange = (event) => {
    this.setState({
      uploading: true
    });
    let reader = new FileReader();
    reader.readAsText(event.target.files[0]);

    reader.onload = function () {
      this.setState({
        csvfile: reader.result,
        uploading: false
      });
    }.bind(this);
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  };
  onChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      [field]: value
    }));
  };
  handleKeyPress = (charCode) => {
    if (charCode === 13) {
      this.getTags();
    }
  };
  toggleButton() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  onNewTagChange = (value, field) => {
    let nt = this.state.new_tag;
    nt[field] = value;
    this.setState((state) => ({
      ...state,
      newTag: nt
    }));
  };
  onCheckboxChange = (value, field) => {
    this.setState((state) => ({
      ...state,
      ['selected_tags']: !state[field]
    }));
  };
  onTagSelect = (tag) => {
    let selected_tags = [];
    selected_tags = this.state.selected_tags;

    if (selected_tags.includes(tag)) {
      var index = selected_tags.indexOf(tag);
      if (index > -1) {
        selected_tags.splice(index, 1);
      }
    } else {
      selected_tags.push(tag);
    }

    if (this.state.tags.length == this.state.selected_tags.length) {
      this.setState({
        selectAll: true
      });
    } else {
      this.setState({
        selectAll: false
      });
    }

    this.setState({
      selected_tags: selected_tags
    });
  };
  onAnimalSelect(animal) {
    let animals = this.state.selected_animals;
    if (animals.includes(animal)) {
      var index = animals.indexOf(animal);
      if (index > -1) {
        animals.splice(index, 1);
      }
    } else {
      animals.push(animal);
    }
    this.setState({
      selected_animals: animals
    });
  }
  componentDidMount() {
    this.getTags();
    this.getAnimals();
    this.getFarms();
  }

  componentDidUpdate() {
    const { socket } = this.context;
    if (socket && !this.state.socket) {
      this.setState({
        socket
      });

      socket.on('tagStatus', (data) => {
        this.setState({
          tags: this.state.tags.map((item) => {
            let status = item.status;
            if (data.ids.includes(item.id)) {
              status = data.status;
            }
            return {
              ...item,
              status
            };
          })
        });
      });
    }
  }

  async getTags() {
    let response = await axios.get('tags', {
      params: {
        query: this.state.query,
        farm_ids: this.state.filters.farms?.map((x) => x.value),
        geofence_ids: this.state.filters.geofences?.map((x) => x.value),
        site_ids: this.state.filters.sites?.map((x) => x.value),
        label_ids: this.state.filters.labels?.map((x) => x.value)
      }
    });
    if (response.status == 200) {
      this.setState(
        {
          tags: response.data,
          tableData: response.data.slice(0, 25)
        },
        () => this.getAnimals()
      );
    }
  }
  async linkTag() {
    let animalId = this.state.selected_animals[0];
    let animal = this.state.animals.filter((a) => a.id == animalId)[0];
    let response;
    if (this.state.re_linking) {
      response = await axios.put('links', {
        tag_id: this.state.selected_tags_unlinked[0],
        animal_id: animal.id
      });
    } else {
      response = await axios.post('links', {
        tag_id: this.state.selected_tags_unlinked[0],
        animal_id: animal.id
      });
    }
    if (response.status == 200) {
      this.getTags();
      this.toggleModal('link_modal');
      this.setState({
        re_linking: false,
        linking: false
      });
      window.location.reload();
    }
  }
  async addTag() {
    await axios.post('tags', {
      ...this.state.new_tag
    });
    this.toggleModal('add_modal');
    this.getTags();
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleModal(modal) {
    this.setState((state) => ({
      ...state,
      [modal]: !state[modal]
    }));
  }
  async getAnimals() {
    let response = await axios.get(
      'animals?query=' + this.state.search_livestock
    );
    if (response.status == 200) {
      this.setState({
        animals: response.data
      });
    }
  }
  setDeleteTag(val) {
    this.setState({
      ...this.state,
      delete_tags: [val]
    });
    this.toggleModal('delete_modal');
  }
  setDeleteTags() {
    let selected;
    selected = this.state.selected_tags;
    //   if (this.state.activeTab == "1"){
    // 	selected = this.state.selected_tags_linked
    //   } else {
    // 	selected = this.state.selected_tags_unlinked
    //   }
    this.setState({
      ...this.state,
      delete_tags: selected
    });
    this.toggleModal('delete_modal');
  }
  async deleteTag() {
    let response = await axios.delete('tags/bulk', {
      data: {
        ids: this.state.delete_tags
      }
    });
    if (response.status == 200) {
      this.getTags();
      this.toggleModal('delete_modal');
    }
  }
  getLabel(id) {
    let tags = this.state.tags.filter((t) => t.id == id);
    if (tags.length > 0) {
      return tags[0].diagri_id;
    } else {
      return '';
    }
  }
  handleCSVFarmChange = (selectedOption) => {
    this.setState({ csv_farm: selectedOption });
  };
  handleFarmChange = (selectedOption) => {
    this.setState({
      selected_farm: selectedOption,
      new_tag: { ...this.state.new_tag, farm_id: selectedOption.value }
    });
  };
  async uploadCSV() {
    let csv = this.state.csvfile;
    let response = await axios.post('tags/csv', {
      csv: csv,
      farm_id: this.state.csv_farm.value
    });
    if (response.status == 200) {
      this.getTags();
      this.toggleModal('csv_modal');
    }
  }
  async getFarms() {
    let response = await axios.get('farms/', {
      query: this.state.query || null
    });
    if (response.status == 200) {
      let mapped_farms = [];
      for (let farm of response.data) {
        mapped_farms.push({
          value: farm.id,
          label: farm.name
        });
      }
      this.setState({
        farms: response.data,
        farm_opts: mapped_farms
      });
    }
  }
  filtersUpdated(filters) {
    this.setState(
      {
        filters: filters
      },
      () => this.getTags()
    );
  }
  filtersClose() {
    this.setState({ filters_open: false });
  }
  onClickCancelEdit() {
    this.setState({
      editableIds: []
    });
  }
  async onClickSaveEdit() {
    const ids = this.state.editableIds;
    const updatedData = this.state.tableData.filter((item) =>
      ids.includes(item.id)
    );
    const tags = updatedData.map((i) => {
      return { farm_id: i.farm?.id, id: i.id };
    });
    const payload = {
      tags: tags
    };
    try {
      await TagService.updateMany(payload);
      this.setState({
        editableIds: [],
        selected_tags: []
      });
      this.getTags();
    } catch (error) {
      console.error(error);
    }
  }
  editTags() {
    this.setState({
      editableIds: [...this.state.selected_tags]
    });
  }
  render() {
    const columns = [
      {
        Header: (cellinfo) => (
          <div className="position-relative form-check">
            <SelectionPopover
              isOpen={this.state.isOpenSelectionPopover}
              onClickOnThisPage={this.onClickOnThisPage}
              onClickAllRecords={this.onClickAllRecords}
              setOpen={(isOpen) =>
                this.setState({
                  isOpenSelectionPopover:
                    isOpen !== undefined
                      ? isOpen
                      : !this.state.isOpenSelectionPopover
                })
              }
              content={
                <input
                  type="checkbox"
                  defaultChecked={this.state.selectAll}
                  onChange={() => {
                    this.onToggleSelectionPopover();
                    if (this.state.selectAll) {
                      this.setState({
                        selectAll: !this.state.selectAll
                      });
                      this.setState((state) => ({
                        ...state,
                        selected_tags: []
                      }));
                    }
                  }}
                />
              }
            />
          </div>
        ),
        id: 'select',
        headerClassName: 'wordwrap',
        accessor: (d) => {
          return { animal: d.animal, id: d.id };
        },
        Cell: (props) => (
          <FormGroup check>
            <Input
              type="checkbox"
              defaultChecked={this.state.selected_tags.includes(props.value.id)}
              value={props.value.id}
              onChange={(e) =>
                this.onTagSelect(
                  parseInt(e.target.value),
                  props.value.animal ? 'linked' : 'unlinked'
                )
              }
              key={props.value.id}
            />{' '}
          </FormGroup>
        ),
        sortable: false,
        filterable: false,
        maxWidth: 85
      },
      /*/
	{
		Header: 'Tag deveui',
		accessor: 'deveui', // String-based value accessors!,
		headerClassName: "wordwrap"
	},
	  //*/
      {
        Header: 'Kraal Tag ID',
        id: 'diagri_id',
        headerClassName: 'wordwrap',
        accessor: (d) => {
          return { id: d.id, identifier: d.diagri_id };
        },
        Cell: (props) => (
          <span className="number">
            <a
              className="underline"
              onClick={() => this.props.history.push('/tag/' + props.value.id)}
              href="javascript:;"
            >
              {props.value.identifier || '/'}
            </a>
          </span>
        ),
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        } // Custom cell components!
      },
      {
        Header: 'Livestock ID',
        id: 'livestockId',
        accessor: (d) => {
          return {
            animal: d.animal,
            identifier:
              d.animal && d.animal.identifier ? d.animal.identifier : ''
          };
        },
        headerClassName: 'wordwrap',
        Cell: (props) =>
          props.value.animal ? (
            <span className="number">
              <a
                className="underline"
                onClick={() =>
                  this.props.history.push('/animal/' + props.value.animal.id)
                }
                href="#"
              >
                {props.value.animal.identifier}
              </a>
            </span>
          ) : (
            'Unlinked'
          ),
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier);
        } // Custom cel
      },
      {
        Header: 'Farm Name',
        accessor: 'farm',
        headerClassName: 'wordwrap',
        minWidth: 200,
        Cell: (cellProps) => {
          if (this.state.editableIds.includes(cellProps.original.id)) {
            const farmOptions = this.state.farm_opts;
            const currentFarmOption = farmOptions?.find(
              (f) => f.value === cellProps?.value?.id
            );
            return (
              <Select
                value={currentFarmOption}
                onChange={(e) => {
                  let farm = { id: e.value, name: e.label };
                  let data = [...this.state.tableData];
                  data[cellProps.index].farm = { ...farm };
                  this.setState({ tableData: data });
                }}
                options={farmOptions}
              />
            );
          }
          return cellProps?.value?.name ?? '';
        }
      },
      {
        Header: 'Location',
        id: 'animall',
        accessor: (d) => {
          return {
            id: d.animal ? d.animal.id : '',
            name:
              d.animal && d.animal.geofences
                ? d.animal.geofences.length > 0
                  ? d.animal.geofences[0]['name']
                  : ''
                : '',
            identifier:
              d.animal && d.animal.geofences
                ? d.animal.geofences.length > 0
                  ? d.animal.geofences[0]['name']
                  : ''
                : ''
          };
        },
        headerClassName: 'wordwrap',
        Cell: (props) => (
          <a
            href="#"
            onClick={() =>
              this.props.history.push('tracking/' + props.value.id)
            }
          >
            {props.value.name}
          </a>
        ),
        sortMethod: (a, b) => {
          return a.name.localeCompare(b.name);
        },
        minWidth: 150
      },
      {
        Header: 'Battery Status',
        id: 'battery_status',
        accessor: (d) => {
          return {
            id: `bv_${d.id}`,
            percentage: d.battery_percentage,
            identifier: d.battery_status_label,
            status: d.battery_status
          };
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return (a.percentage || 0) - (b.percentage || 0);
        },
        Cell: (props) => (
          <div>
            <StatusIndicator key={props.value.id} status={props.value.status} />
            {props.value.identifier}
          </div>
        )
      },
      {
        Header: 'Connection Status',
        id: 'status',
        accessor: (d) => {
          return { id: d.id, status: d.status, identifier: d.status };
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.status.localeCompare(b.status);
        },
        Cell: (props) => (
          <div>
            <StatusIndicator key={props.value.id} status={props.value.status} />
            {props.value.status?.charAt(0).toUpperCase() +
              props.value.status?.slice(1)}
          </div>
        )
      },
      {
        Header: 'Last Reading',
        id: 'timestamp_at',
        accessor: (item) => {
          return item.timestamp_at
            ? moment(item.timestamp_at).format(DATE_FORMAT.DATETIME)
            : null;
        },
        headerClassName: 'wordwrap',
        sortable: true,
        sortMethod: (a, b) => {
          return moment(b).format('x') - moment(a).format('x');
        },
        minWidth: 170
      },
      {
        Header: 'Linked Status',
        id: 'animal',
        accessor: (d) => {
          return {
            animal: d.animal,
            identifier: d.animal != null ? 'Linked' : 'Not Linked'
          };
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier);
        },
        Cell: (props) => (
          <div>{props.value.animal != null ? 'Linked' : 'Not Linked'}</div>
        )
      }

      /*/
	  , {
			Header: 'Tag Labels',
			accessor: 'labels',
			headerClassName: "wordwrap",
			Cell: props => { return props.value.length > 0 ? props.value.map(x => <a href="#" onClick={e=>this.props.history.push("label/" + x.id)}><div className="label-tag" style={ {"background": x.colour, color: "white"}}>{x.name}</div></a>): "/"},
			sortMethod: (a, b) => {
				return a[0].name.localeCompare(b[0].name, undefined, {numeric: true, sensitivity: 'base'});
			} // Custom cel
			},{
		Header: 'Tag Version',
		accessor: 'version',
		headerClassName: "wordwrap",
	  },{
		Header: 'Tag Firmware Version',
		accessor: 'firmware_version',
		headerClassName: "wordwrap",
		default: "/"
		}
//*/
    ];
    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;

      if (row[id] && typeof row[id] == 'object') {
        if (typeof row[id]['identifier'] == 'number') {
          return row[id]['identifier'] !== undefined
            ? String(row[id]['identifier']).includes(filter.value)
            : true;
        } else {
          return row[id] !== undefined
            ? String(row[id]['identifier'].toLowerCase()).includes(
                filter.value.toLowerCase()
              )
            : true;
        }
      } else if (typeof row[id] == 'number') {
        return row[id] !== undefined
          ? String(row[id]).includes(filter.value)
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
          : true;
      }
    }

    return (
      <div>
        <Filters
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm', 'label', 'geofence', 'site']}
        ></Filters>

        {/*/ }
		<Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Linked Tags
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Unlinked Tags
            </NavLink>
          </NavItem>
        </Nav>
		{ //*/}
        <Row>
          <Col xs="12" md="12" lg="12">
            {/*/ }
			<TabContent className="no-bg" activeTab={this.state.activeTab}>
				<TabPane tabId="1">
				{ //*/}
            <Card>
              <Row className="pad-10 tag-in-row">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="">Kraal Tags</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Kraal Tags</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>

                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg="6"
                  className="d-flex justify-content-end align-items-center h-100 tag-in-col-right"
                >
                  {this.state.editableIds.length ? (
                    <div>
                      <Button
                        color="primary"
                        className="float-right"
                        onClick={() => this.onClickSaveEdit()}
                      >
                        Save
                      </Button>
                      <Button
                        className="outline float-right"
                        onClick={() => this.onClickCancelEdit()}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <>
                      <InputGroup className="head-search mr-2" size="sm">
                        <Input
                          type="search"
                          id="search"
                          name="search"
                          defaultValue={this.state.query}
                          onChange={(e) =>
                            this.onChange(e.target.value, 'query')
                          }
                          placeholder="Search Kraal Tags"
                          onKeyPress={(e) => this.handleKeyPress(e.charCode)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText
                            onClick={() => this.handleKeyPress(13)}
                            className="rg-pointer"
                          >
                            <i className="fas fa-search"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      <ButtonDropdown
                        className="float-right"
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggleButton}
                      >
                        <DropdownToggle caret>Actions</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => this.toggleModal('add_modal')}
                          >
                            Add Kraal Tag
                          </DropdownItem>
                          {this.state.selected_tags.length > 0 && (
                            <DropdownItem onClick={() => this.editTags()}>
                              Edit Kraal Tags
                            </DropdownItem>
                          )}
                          {this.state.selected_tags.length > 0 && (
                            <DropdownItem onClick={() => this.setDeleteTags()}>
                              Archive Kraal Tag
                            </DropdownItem>
                          )}
                          <DropdownItem
                            onClick={() => {
                              this.toggleModal('csv_modal');
                            }}
                          >
                            Upload Kraal Tag list
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>

                      <Button
                        color="primary"
                        className="float-right mr-2"
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            filters_open: !this.state.filters_open
                          })
                        }
                      >
                        <i className="fa fa-filter"></i>Filters
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12" className="allow-overflow">
                  <ReactTable
                    showPagination={this.state.tags.length > 0}
                    minRows={0}
                    data={this.state.tags}
                    columns={columns}
                    resizable={true}
                    defaultPageSize={25}
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                    onFetchData={(props) => {
                      const data = props.data.length
                        ? props.sortedData.slice(0, props.pageSize)
                        : this.state.tags;
                      this.setState({ tableData: data });
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.delete_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive tags</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive selected tags? This action cannot
            be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('delete_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteTag()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.add_modal} className={this.props.className}>
          <ModalHeader>
            <h6>
              <b>Add Kraal Tag</b>
            </h6>
          </ModalHeader>
          <ModalBody>
            <br />
            <br />
            <Form>
              <FormGroup row>
                <Label sm="3">Tag DEVEUI</Label>
                <Col sm="9">
                  <Input
                    type="text"
                    placeholder="DEVEUI"
                    onChange={(e) =>
                      this.onNewTagChange(e.target.value, 'deveui')
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Kraal Tag ID</Label>
                <Col sm="9">
                  <Input
                    type="text"
                    placeholder="Kraal Tag ID"
                    onChange={(e) =>
                      this.onNewTagChange(e.target.value, 'diagri_id')
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Tag Version</Label>
                <Col sm="9">
                  <Input
                    type="text"
                    placeholder="Tag Version"
                    onChange={(e) =>
                      this.onNewTagChange(e.target.value, 'version')
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Tag Firmware Version</Label>
                <Col sm="9">
                  <Input
                    type="text"
                    placeholder="Firmware Version"
                    onChange={(e) =>
                      this.onNewTagChange(e.target.value, 'firmware_version')
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Tag farm</Label>
                <Col sm="9">
                  <Select
                    value={this.state.selected_farm}
                    onChange={this.handleFarmChange}
                    options={this.state.farm_opts}
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('add_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.addTag()}>
              Add tag
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.link_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Linking confirmation</b>
            </h5>
            <br />
            <br />
            <br />
            <p className="text-center">
              Tag <b>{this.getLabel(this.state.selected_tags_unlinked[0])}</b>{' '}
              will be linked to
            </p>
            <Animal
              animals={this.state.animals}
              animal={this.state.selected_animals}
            ></Animal>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('link_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.linkTag()}>
              Link
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={this.state.csv_modal}
          className={this.props.className}
        >
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>CSV file upload</b>
            </h5>
            <br />
            <br />
            <br />
            <br />
            Download{' '}
            <a
              href="/files/tags_import_example.csv"
              style={{ fontWeight: 'bold' }}
            >
              example
            </a>{' '}
            CSV file.
            <br />
            <br />
            <FormGroup>
              <Label>Select farm</Label>
              <Select
                value={this.state.csv_farm}
                onChange={this.handleCSVFarmChange}
                options={this.state.farm_opts}
              />
            </FormGroup>
            <FormGroup>
              <Label>CSV file</Label>
              <Input type="file" onChange={this.handleCSVChange}></Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.toggleModal('csv_modal')}
            >
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.uploadCSV()}>
              Upload
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Tags;
